import { FC } from 'react';

interface Props {
    mediaStream: MediaStream;
    isMuted?: boolean;
}

export const VideoFeed: FC<Props> = ({
    mediaStream,
    isMuted = false,
}) => {
    return (
        <audio
            ref={(ref) => {
                if (ref) {
                    ref.srcObject = mediaStream;
                }
            }}
            autoPlay={true}
            // muted={isMuted}
        />
    );
};