import React, { FC, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import WaveSurfer from 'wavesurfer.js';

interface AudioMessageDummyProps {
    isSender: boolean
    audioBlob: BlobPart
}
const AudioMessageDummy: FC<AudioMessageDummyProps> = ({ isSender, audioBlob }) => {
    const blob = new Blob([audioBlob], { type: 'text/plain' });

    const waveformRef = useRef<WaveSurfer | null>(null);
    const waveRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (blob && waveRef.current) {
            waveformRef.current = WaveSurfer.create({
                barWidth: 3,
                barRadius: 50,
                cursorWidth: 1,
                container: waveRef.current,
                backend: 'WebAudio',
                autoCenter: true,
                height: 26,
                progressColor: '#000',
                waveColor: isSender ? '#fff' : '#aaa',
                cursorColor: 'transparent',
                interact: false,
                dragToSeek: false
            });

            waveformRef.current.loadBlob(blob);
        }

        return () => {
            waveformRef.current?.destroy();
        };
    }, [blob]);

    return (
        <div className="WaveformContainer">
            <button className="PlayButton" disabled>
                <FontAwesomeIcon icon={faPlay} size='lg' />
            </button>
            <div ref={waveRef} className="Wave" />
        </div>
    );
};

export default AudioMessageDummy;
