import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { logoutHandler, resetPasswordHandler } from 'src/store/slices/authSlice';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const ResetPassword = () => {
    //single hooks starts here
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const { token } = useParams();
    const { t } = useTranslation('common');
    //single hooks ends here


    //useStates starts here
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(true)
    const [showConfirmPassword, setShowConfirmPassword] = useState(true)
    //useStates ends here


    //useEffect starts here
    useEffect(() => {
        dispatch(logoutHandler());
    }, []);
    //useEffect ends here

    //formik starts here
    const initialValues: any = {
        password: "",
        confirmPassword: ''
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required(t('validation.requiredField')).transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .strict(true)
            .matches(/^[A-Za-z0-9#@$\s]+$/, (t('validation.specialcharacterPassword')))
            .test(
                "len",
                t('validation.passwordLimitMin'),
                (val: any) => {
                    const trimmedValue = val && val.toString().trim();
                    return trimmedValue && trimmedValue.length >= 6;
                }

            )
            .test(
                "len",
                t('validation.passwordLimitMax'),
                (val: any) => {
                    const trimmedValue = val && val.toString().trim();
                    return trimmedValue && trimmedValue.length <= 40;
                }

            ).test(
                "no-spaces",
                t('trimMessage.trimTitle'),
                (val: any) =>
                    val &&
                    val.trim() !== ''
            ),

        confirmPassword: Yup.string()
            .required(t('validation.requiredField')).transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .strict(true)
            .matches(/^[A-Za-z0-9#@$\s]+$/, (t('validation.specialcharacterPassword')))
            .test(
                "len",
                t('validation.passwordLimitMin'),
                (val: any) => {
                    const trimmedValue = val && val.toString().trim();
                    return trimmedValue && trimmedValue.length >= 6;
                }

            )
            .test(
                "len",
                t('validation.passwordLimitMax'),
                (val: any) => {
                    const trimmedValue = val && val.toString().trim();
                    return trimmedValue && trimmedValue.length <= 40;
                }

            ).test(
                "no-spaces",
                t('trimMessage.trimTitle'),
                (val: any) =>
                    val &&
                    val.trim() !== ''
            ).oneOf([Yup.ref('password')], t('validation.PasswordMustMatch'))
    });
    //formik ends here 


    //functions starts here
    const handleRegister = async (formValue: any) => {
        const password = formValue?.password
        try {
            setLoading(true);

            const data = {
                token, password
            }

            await dispatch(resetPasswordHandler(data)).then((result: any) => {
                setLoading(false);
                if (result?.payload?.statusCode === 200) {
                    toast.success(result?.payload?.message, {
                        position: 'top-right',
                        autoClose: 2500,
                    });
                    setTimeout(() => {
                        navigate('/signin')
                    }, 1000);
                }
                else if (result?.payload?.statusCode === 401) {
                    toast.error(result?.payload?.message, {
                        position: 'top-right',
                        autoClose: 2500,
                    });

                }
                else if (result?.payload?.statusCode === 400) {
                    toast.error(result?.payload?.message, {
                        position: 'top-right',
                        autoClose: 2500,
                    });

                }

            }).catch((error: any) => {
                console.log(error)
            })
        } catch (error) {
            console.error(error, "error");
        }

    };
    //functions ends here

    return (
        <div className="signup-wrap d-flex flex-column">
            <div className="hstack justify-content-center flex-fill">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-8 col-xxl-4 col-xl-6 my-1">
                            <div className="card border-0 rounded-4">
                                <div className="card-body d-flex flex-column flex-fill justify-content-center px-3 px-sm-5">
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleRegister}
                                    >
                                        <Form>
                                            <h1 className="fs-4 fw-bold text-center mb-3">{t('common.ResetNewPassword')}</h1>
                                            <div className="password-toggle mb-4">
                                                <Field name="password" placeholder={t('ChangePassword.newPassword')}
                                                    className="form-control" type={showPassword ? "password" : "text"} />
                                                <button type='button' onClick={() => setShowPassword(prevState => !prevState)} className="password-toggle-btn btn btn-link p-0 text-black">
                                                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                                                </button>
                                                <ErrorMessage name="password" component="div" className="text-danger txt-error" />
                                            </div>
                                            <div className="password-toggle mb-4">
                                                <Field name="confirmPassword" type={showConfirmPassword ? "password" : "text"} placeholder={t('ChangePassword.confirmPassword')}
                                                    className="form-control" />
                                                <button type='button' onClick={() => setShowConfirmPassword(prevState => !prevState)} className="password-toggle-btn btn btn-link p-0 text-black">
                                                    <FontAwesomeIcon icon={showConfirmPassword ? faEye : faEyeSlash} />
                                                </button>
                                                <ErrorMessage name="confirmPassword" component="div" className="text-danger txt-error" />

                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <button className="btn btn-primary rounded-3 hstack gap-2" type="submit" disabled={loading}>
                                                    {loading && <Spinner animation={'border'} size='sm' />}
                                                    {t('common.SetNewPassword')}
                                                </button>
                                            </div>

                                        </Form>
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword
