import React from 'react'

const Typing = () => {
    return (
        <span className="typing">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
        </span>
    )
}

export default Typing