import React from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { shareChatRoomModalHandler } from 'src/store/slices/modalSlice'
import { RootState } from 'src/store/store'
import ShareChatRoomDetails from '../ShareChatRoom/ShareChatRoomDetails'

const ShareChatRoomModal = () => {
    const dispatch = useDispatch()
    const { shareChatRoomModal } = useSelector((state: RootState) => state.modal);

    const hideModal = () => {
        dispatch(shareChatRoomModalHandler(false))
    }
    return (
        <Modal centered show={shareChatRoomModal} onHide={hideModal}>
            <Modal.Header closeButton>
                <Modal.Title>Share ChatRoom</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ShareChatRoomDetails />
            </Modal.Body>
        </Modal>
    )
}

export default ShareChatRoomModal