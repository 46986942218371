import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

export const createChatRoom = async (createdBy: string, title: string, files: any[], isPrivate: any, password: string) => {


    let formData = new FormData();
    formData.append('createdBy', createdBy);
    formData.append('title', title);
    formData.append('chatImage', files[0]);
    formData.append('isPrivate', isPrivate);
    formData.append('password', password);
    let newHeader = {
        "Content-Type": "multipart/form-data",
    }
    return axios({
        url: API_URL + "chat-room/create-chatroom",
        method: "post",
        headers: newHeader,
        data: formData
    })
};

export const getAllChatRooms = async () => {
    return axios
        .get(API_URL + "chat-room/get-all-chatrooms")
        .then((response) => {
            return response.data;
        });
};

export const getChatRoomByCode = async (code: string) => {
    return axios
        .get(API_URL + "chat-room/" + code)
        .then((response) => {
            return response.data;
        });
};
export const deleteChatRoomById = async (code: string) => {
    return axios
        .delete(API_URL + "chat-room/" + code)
        .then((response) => {
            return response.data;
        });
};
export const updateChatRoomById = async (code: string, title: string, files: any[],isPrivate: any , password: string) => {
    let formData = new FormData();
    formData.append('title', title);
    formData.append('chatImageUpdate', files[0]);
    formData.append('isPrivate', isPrivate);
    formData.append('password', password);
    let newHeader = {
        "Content-Type": "multipart/form-data",
    }
    return axios({
        url: API_URL + "chat-room/" + code,
        method: "put",
        headers: newHeader,
        data: formData
    })
}

export const chatRoomImageDelete = async (uuid: any) => {
    return axios
        .put(API_URL + "chat-room/chatroom-image-delete/"+ uuid,)
        .then((response) => {
            return response.data;
        });
}


