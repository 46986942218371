import { faBars, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store/store'
import Logo from 'src/assets/images/logo2.svg'
import { useTranslation } from 'react-i18next'
import LangIcon from "src/assets/images/lang-icon.svg"

const GuestHeader = () => {
    const { t } = useTranslation('common');
    const { user } = useSelector((state: RootState) => state.auth)

    return (
        <header className="shadow-sm header navbar navbar-expand-sm bg-white sticky-top py-3">
            <nav className="container-fluid justify-content-start bg-white">
                <div className="navbar-brand headerlogo hstack gap-1">
                    <img src={Logo} alt="logo" />
                    <span>{t('common.AppName')}</span>
                </div>

                <div className='flex-fill text-end'>
                    <button className="navbar-toggler p-0 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span><FontAwesomeIcon icon={faBars} /></span>
                    </button>
                </div>
                <div className="collapse navbar-collapse justify-content-end flex-grow-0" id="navbarNav">
                    <ul className="navbar-nav gap-3 pt-3 pt-sm-0">
                        <li className="nav-item hstack gap-1 text-muted">
                            <span><img src={LangIcon} alt="" height={16} /></span>
                            <span>{user?.user?.language?.label}</span>
                        </li>
                        <li className="nav-item hstack gap-1 text-muted">
                            <span><FontAwesomeIcon icon={faUser} /></span>
                            <span>{user?.user?.firstName}</span>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    )
}

export default GuestHeader