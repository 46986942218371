import React from 'react'
import ChatRoomList from '../ChatRoom/ChatRoomList'


const Dashboard = () => {
  return (
    <div className='pt-3 pt-xl-5 pb-4 flex-fill vstack container'>
      <ChatRoomList />
    </div>
  )
}

export default Dashboard