import React from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { RootState } from './store/store';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Signin from './pages/Authentication/Signin';
import Signup from './pages/Authentication/Signup';
import Dashboard from './pages/Dashboard/Dashboard';
import ChatRoom from './pages/ChatRoom/ChatRoom';
import Profile from './pages/Profile/Profile';
import Header from './components/Header';
import ForgotPassword from './pages/Authentication/ForgotPassword';
import ResetPassword from './pages/Authentication/ResetPassword';
import AuthVerify from './common/AuthVerify';
import JoinPrivateChatroom from './pages/ChatRoom/JoinPrivateChatroom';
import JoinPublicChatroom from './pages/ChatRoom/JoinPublicChatroom';
import { VideoChatRoom } from './pages/Streaming/VideoChatRoom';
import { useLocalCameraStream } from './pages/Streaming/useLocalCameraStream';

const App = () => {
  const { isUser } = useSelector((state: RootState) => state.auth)

  const { localStream } = useLocalCameraStream();

  return (
    <div className="App vstack">
      <Router >
        <ToastContainer />
        {
          !isUser ?
            <Routes>
              <Route path='*' caseSensitive={false} element={<Signin />} />
              <Route path="/signin" caseSensitive={false} element={<Signin />} />
              <Route path="/signup" caseSensitive={false} element={<Signup />} />
              <Route path="/forgot-password" caseSensitive={false} element={<ForgotPassword />} />
              <Route path="/reset-password/:token" caseSensitive={false} element={<ResetPassword />} />
              <Route path='/chatroom/:code' caseSensitive={false} element={<ChatRoom />} />
              <Route path='/chatroom/private/:code' caseSensitive={false} element={<JoinPrivateChatroom />} />
              <Route path='/chatroom/public/:code' caseSensitive={false} element={<JoinPublicChatroom />} />
            </Routes>
            :
            <>
              <AuthVerify />
              <Header />

              <Routes>
                <Route path='*' caseSensitive={false} element={<Dashboard />} />

                <Route path='/chatroom/:code' caseSensitive={false} element={<ChatRoom />} />
                <Route path='/chatroom/private/:code' caseSensitive={false} element={<JoinPrivateChatroom />} />
                <Route path='/chatroom/public/:code' caseSensitive={false} element={<JoinPublicChatroom />} />
                <Route path='/profile' caseSensitive={false} element={<Profile />} />

                <Route
                  path="streaming-room/:roomName"
                  element={localStream && <VideoChatRoom localStream={localStream} />}
                />
              </Routes>
            </>
        }
      </Router>
    </div>
  );
}

export default App;
