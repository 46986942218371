import React, { FC, useState } from "react"
import { Language, IMessageX } from "src/common/commonTypes"
import TranscriptIcon from "src/assets/images/transcript.svg"
import HideTranscriptIcon from "src/assets/images/hideTranscript.svg"
import DefaultProfileImg from "src/assets/images/DefaultProfileImg.svg"
import AudioMessage from "./AudioMessage";
import { OverlayTrigger, Tooltip } from "react-bootstrap"

interface MessageBubbleProps {
    userId: string;
    message: IMessageX;
    language: Language;
}

const MessageBubble: FC<MessageBubbleProps> = React.memo(({ userId, message, language }) => {
    const [showTranscription, setShowTranscription] = useState<boolean>(false)
    const [showTranslation, setShowTranslation] = useState<boolean>(false)

    const isAudio = message.type === "audio"

    const transcription = message.transcriptionText
    const isTranscription = !!transcription && transcription !== ''

    const translation = message.translatedMessage
    const isTranslation = !!translation && translation !== ''

    const isSender: boolean = message.userId === userId
    const isSameLanguage: boolean = message.language.value === language?.value

    const audioMessage = isSender && isSameLanguage ? message.originalMessage : message.translatedMessage
    const textMessage = isSender ? message.originalMessage : message.translatedMessage

    const username = message.username.firstName + ' ' + message.username.lastName
    const userImg = message.username?.profileImage ?? DefaultProfileImg

    const timestamp = message.createdAt;
    const dateObj = new Date(timestamp);
    const formattedTime = dateObj.toLocaleTimeString('en-US', { hour12: true, hour: 'numeric', minute: 'numeric' });

    const shouldShowToggle = isAudio ? isTranscription : (!isSender && !isSameLanguage && isTranslation);
    const shouldShowBubble = isAudio ? (isTranscription && showTranscription) : (!isSender && isTranslation && showTranslation);

    const showState = isAudio ? showTranscription : showTranslation;
    const setShowState = isAudio ? setShowTranscription : setShowTranslation;

    const icon = showState ? HideTranscriptIcon : TranscriptIcon;
    const translationOrTranscription = isAudio ? transcription : message.originalMessage;

    return (
        <div className={`hstack align-items-start gap-2 ${isSender ? 'sender flex-row-reverse' : 'receiver'}`}>
            {/* <OverlayTrigger key={message.messageId} overlay={<Tooltip id={`tooltip-${message.messageId}`} className="position-fixed">{username}</Tooltip>}>
                <div className="bubble-profile-image">
                    <img src={userImg} className="w-100 h-100 object-fit-cover" alt={username} />
                </div>
            </OverlayTrigger> */}
            <div className="bubble-profile-image">
                <img src={userImg} className="w-100 h-100 object-fit-cover" alt={username} />
                <div className="custom-tooltip">{username}</div>
            </div>
            <div className="flex-fill">
                <div className={`hstack gap-2 ${isSender ? 'flex-row-reverse' : ''}`}>
                    <div className={`bubble ${showState ? 'rounded-bottom-1' : ''} bubble-${isAudio ? 'audio' : 'text'}`}>
                        {isAudio ?
                            <AudioMessage url={audioMessage} isSender={isSender} />
                            :
                            textMessage
                        }
                        <div className="message-time">{formattedTime}</div>
                    </div>

                    {shouldShowToggle &&
                        <button type="button" onClick={() => setShowState(!showState)} className='btn btn-link p-0' style={{ height: 26 }}>
                            <img src={icon} height={showState ? 26 : 20} alt="" />
                        </button>
                    }
                </div>

                {shouldShowBubble &&
                    <div className="bubble bubble-transcription rounded-top-1 mt-1">{translationOrTranscription}</div>
                }
            </div>
        </div>
    );
});

export default MessageBubble;