import React, { FC } from 'react'
import { LoadingMessage } from 'src/common/commonTypes'
import DummyMessage from './DummyMessage'

interface DummyMessageListProps {
    dummyMessageList: LoadingMessage[]
}
const DummyMessageList: FC<DummyMessageListProps> = ({ dummyMessageList }) => {
    return (
        <>
            {dummyMessageList?.map((dummyMessage: any, index: number) => {
                return <DummyMessage message={dummyMessage} key={`dummy-messge-${index}`} />
            })}
        </>
    )
}

export default DummyMessageList