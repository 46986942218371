import React, { useEffect, useState, ChangeEvent, useRef } from 'react'
import socket from 'src/common/socket'
import { Language, RequestData, ITranslateMessage, IMessageX, IReceivedMessageX, ICreateMessageX, ErrorMessage, AudioFormat, LoadingMessage } from 'src/common/commonTypes';
import Typing from 'src/pages/ChatRoom/components/Typing';
import { JOIN_CHATROOM_SEND, LEAVE_CHATROOM_SEND, MESSAGE_HIDE_TYPING_RES, MESSAGE_HIDE_TYPING_SEND, MESSAGE_RES, MESSAGE_SEND, MESSAGE_SEND_FAILED, MESSAGE_SHOW_TYPING_RES, MESSAGE_SHOW_TYPING_SEND, UPDATE_CHATROOM_RES } from 'src/common/constants';
import MessageBubble from './components/MessageBubble';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from 'src/store/store';
import { getAllMessagesByChatRoomIdHandler } from 'src/store/slices/messagesSlice';
import { Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { dictationModeHandler } from 'src/store/slices/settingsSlice';
import { ChatroomDeletedHandler, exitChatRoomByIdHandler, getChatRoomByCodeHandler, ScrollBarAtBottomHandler, UpdateChatRoom } from 'src/store/slices/chatRoomSlice';
import { toast } from 'react-toastify';
import { MESSAGE_GET_ALL } from 'src/store/actions';
import VADWithReact from './VAD/VADWithReact';
import ShareChatRoomDetails from 'src/components/ShareChatRoom/ShareChatRoomDetails';
import MessageBox from './components/MessageBox';
import DummyMessageList from './components/DummyMessage/DummyMessageList';
import { shareChatRoomModalHandler } from 'src/store/slices/modalSlice';
import ShareChatRoomModal from 'src/components/Modals/ShareChatRoomModal';
import GuestHeader from 'src/components/GuestHeader';
import { logoutHandler } from 'src/store/slices/authSlice';
import { getJoinedUserHandler } from 'src/store/slices/joinedUsersSlice';
import * as ACTION from "src/store/actions/index";

const ChatRoom = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { code } = useParams();
  const { t } = useTranslation('common');


  const { currentChatRoom, scrollBarAtBottom, isChatroomDeleted } = useSelector((state: RootState) => state.chatRoom)
  const { user, isUser } = useSelector((state: RootState) => state.auth)
  const { isDictationMode } = useSelector((state: RootState) => state.settings)
  const userId = user?.user?.uuid
  const language = user?.user?.language
  const currentChatRoomId = currentChatRoom?.uuid ?? ''
  const currentChatRoomCode = currentChatRoom?.code ?? ''



  //useRef starts here
  const messageListRef = useRef<HTMLDivElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messagesMiddleRef = useRef<HTMLDivElement>(null);
  const reachedEndRef = useRef<boolean>(false);
  const scrollBarAtEnd = useRef<boolean>(false);
  const pageSizeRef = useRef<number>(1)
  //useRef ends here


  //useState starts here
  const [message, setMessage] = useState<string>('')
  const [messageList, setMessageList] = useState<IMessageX[]>([])
  const [typing, setTyping] = useState<boolean>(false)
  const [lines, setLines] = useState<number>(1);
  const [messageLoading, setMessageLoading] = useState<boolean>(false)
  const [dummyMessageList, setDummyMessageList] = useState<LoadingMessage[]>([])
  const [perPage, setperPage] = useState(10);
  const [sortColumn, setsortColumn] = useState<any>({ order: "desc", prop: "createdAt" });
  const [totalItems, setTotalItems] = useState(0);
  const [pageLoading, setPageLoading] = useState<boolean>(false)
  //useState ends here

  const data: RequestData = { code: currentChatRoomCode, chatroomId: currentChatRoomId, userId: userId }


  //functions starts here
  const showTyping = () => {
    socket.emit(MESSAGE_SHOW_TYPING_SEND, data)
  }

  const hideTyping = () => {
    if (message.trim() !== '') {
      socket.emit(MESSAGE_HIDE_TYPING_SEND, data)
    }
  }

  const sendMessage = async (blob: AudioFormat = null) => {
    if (currentChatRoomId === '') {
      navigate("/")
    }

    const trimmedMessage = message.trim()
    if (trimmedMessage === '' && !isDictationMode) {
      return;
    }
    const sendData: ICreateMessageX = {
      userId: userId,
      chatroomId: currentChatRoomId,
      originalMessage: trimmedMessage,
      sendersLanguage: language,
      audio: blob
    }

    if (blob === null) {
      resetMessageBox()
    }
    const loadingMessage: LoadingMessage = {
      text: trimmedMessage,
      audio: blob,
      failed: false
    }
    setDummyMessageList([...dummyMessageList, loadingMessage])
    socket.emit(MESSAGE_SEND, sendData)
  }

  const resetMessageBox = () => {
    setMessage('')
    setLines(1)
  }

  const handleMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value)
    const newLines: number = e.target.value.split('\n').length;
    setLines(newLines);
    e.target.value.length > 0 ? showTyping() : hideTyping()
  }

  const handleDictationMode = () => {
    setMessage('')
    dispatch(dictationModeHandler(!isDictationMode))
  }

  const getAllMessagesByChatRoomId = async (pageSize: number, showLoading: boolean) => {
    showLoading && setMessageLoading(true);
    const sortColumnProp = sortColumn.prop;
    const sortColumnOrder = sortColumn.order;
    const getMessageData = {
      chatroomId: currentChatRoomId,
      language: language,
      perPage,
      pageSize: pageSize,
      sortColumnProp,
      sortColumnOrder,
    };
    await dispatch(getAllMessagesByChatRoomIdHandler(getMessageData)).then(async (response: any) => {
      if (response.type === `${MESSAGE_GET_ALL}/fulfilled`) {
        const { data, totalItems } = response.payload;
        setMessageList((prevMessages) => [...data, ...prevMessages]);
        setTotalItems(totalItems);
        if (data.length <= 1) {
          reachedEndRef.current = true
        }
      }
      showLoading && setMessageLoading(false);
      if (pageSizeRef.current === 1) {
        dispatch(ScrollBarAtBottomHandler(true))
      }
      else {
        dispatch(ScrollBarAtBottomHandler(false))
      }
    });
  };

  const scrollToBottom = () => {
    if (scrollBarAtBottom === true) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
    } else if (scrollBarAtBottom === false) {
      messagesMiddleRef.current?.scrollIntoView({ behavior: 'auto' });
    }
  };

  const fetchMoreMessagesOnScrollUp = async () => {
    if (!reachedEndRef.current) {
      const newPageSize = pageSizeRef.current + 1;
      pageSizeRef.current = newPageSize
      await getAllMessagesByChatRoomId(newPageSize, false);
    } else {
      console.log("not found");
    }
  };

  const handleScroll = () => {
    if (messageListRef.current) {
      const { scrollTop } = messageListRef.current;
      if (scrollTop === 0) {
        fetchMoreMessagesOnScrollUp();
      }
    }
  };

  const exitChatRoom = async () => {
    if (!isUser) {
      dispatch(logoutHandler())
    }
    hideTyping()
    socket.emit(LEAVE_CHATROOM_SEND, data);
    navigate("/")
    await dispatch(exitChatRoomByIdHandler(data))
  }
  //functions ends here


  // useEffects starts here
  // useLayoutEffect(() => {
  //   if (messageListRef.current) {
  //     messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
  //   }
  // }, []);

  useEffect(() => {
    const messagesListElement = messageListRef.current;
    if (messagesListElement) {
      messagesListElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (messagesListElement) {
        messagesListElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [messageListRef.current]);

  useEffect(() => {
    scrollToBottom();
  }, [scrollBarAtBottom, messageList]);

  useEffect(() => {

    const getJoinedUserData = {
      chatroomId: currentChatRoomId,
      userId: userId,
    };

    if (code === '' || code !== currentChatRoomCode) {
      navigate("/");
      if (!isChatroomDeleted) {
        toast.error("Link is not valid, Please check link!", {
          position: 'top-right',
          autoClose: 2500,
        });
      }
      dispatch(ChatroomDeletedHandler(false))
    } else {
      setPageLoading(true)
      dispatch(getJoinedUserHandler(getJoinedUserData)).then(async (res: any) => {
        if (res?.type === `${ACTION.GET_JOINED_USER}/${ACTION.FULFILLED}`) {
          setPageLoading(false)
          const joinChatroomData = { code: code, chatroomId: currentChatRoomId, userId: userId, user: user }
          socket.emit(JOIN_CHATROOM_SEND, joinChatroomData);
          await getAllMessagesByChatRoomId(pageSizeRef.current, true)
        } else {
          setPageLoading(false)
          navigate('/')
          if (!isChatroomDeleted) {
            toast.error((t('toastMessage.notValidUrl')), {
              position: 'top-right',
              autoClose: 2500,
            })
          }
          dispatch(ChatroomDeletedHandler(false))
        }
      })
    }

  }, [code, currentChatRoomCode, currentChatRoomId, user, userId])

  useEffect(() => {
    socket.on(MESSAGE_RES, async (socketData: any) => {

      let modifiedData: IReceivedMessageX = socketData.modifiedData
      console.log("socketData", socketData);
      let error: ErrorMessage = socketData.errorMessage
      const state = store.getState()
      const socketUserId = state.auth.user.user.uuid
      const isReceiver: boolean = modifiedData.userId !== socketUserId

      if (error === null) {
        const getMessageByLanguage = (messages: ITranslateMessage[], languageValue: Language) => {
          if (!messages || !Array.isArray(messages)) {
            return modifiedData.originalMessage;
          }
          for (const message of messages) {
            if (message.language.value === languageValue.value) {
              return message.message;
            }
          }
          return modifiedData.originalMessage;
        }
        const getTranscriptionText = (messages: ITranslateMessage[], languageValue: Language) => {
          if (messages.length === 0) {
            return ''
          }
          if (!messages || !Array.isArray(messages)) {
            return '';
          }
          for (const message of messages) {
            if (message.language.value === languageValue.value) {
              return message.message;
            }
          }
          return '';
        }
        const translatedMessage = getMessageByLanguage(modifiedData.translatedMessages, language)
        const transcriptionText = getTranscriptionText(modifiedData.transcriptionTexts, language)
        const modifiedDataSocket: IMessageX = {
          userId: modifiedData.userId,
          username: modifiedData.username,
          messageId: modifiedData.messageId,
          chatroomId: modifiedData.chatroomId,
          language: modifiedData.language,
          type: modifiedData.type,
          originalMessage: modifiedData.originalMessage,
          translatedMessage: translatedMessage,
          transcriptionText: transcriptionText,
          createdAt: modifiedData.createdAt,
          updatedAt: modifiedData.updatedAt,
        }
        setDummyMessageList(dummyMessageList.slice(1))
        setMessageList([...messageList, modifiedDataSocket])
        scrollBarAtEnd.current = true
        dispatch(ScrollBarAtBottomHandler(true))

      } else {
        if (error.userId === socketUserId) { //isSender

          // const updateDummyMessageList = [
          //   { ...dummyMessageList[0], failed: true },
          //   ...dummyMessageList.slice(1)
          // ];
          // setDummyMessageList(updateDummyMessageList)

          setDummyMessageList(dummyMessageList.slice(1))
          toast.error(error.message, {
            position: 'top-right',
            autoClose: 2500,
          });
        }
      }

      if (isReceiver && modifiedData.type === 'text') {
        setTyping(false)
      }
    })

    socket.on(MESSAGE_SHOW_TYPING_RES, async (data: RequestData) => {
      const state = store.getState()
      const socketUserId = state.auth.user.user.uuid
      const isReceiver: boolean = data.userId !== socketUserId
      if (isReceiver) {
        setTyping(true)
      }
    })

    socket.on(MESSAGE_HIDE_TYPING_RES, async (data: RequestData) => {
      const state = store.getState()
      const socketUserId = state.auth.user.user.uuid
      const isReceiver: boolean = data.userId !== socketUserId
      if (isReceiver) {
        setTyping(false)
      }
    })

    socket.on(UPDATE_CHATROOM_RES, async (data: any) => {
      const chatroomCode = data?.code
      await dispatch(getChatRoomByCodeHandler(chatroomCode)).then((result: any) => {
        dispatch(UpdateChatRoom(result?.payload))
      })
    })

    socket.on(MESSAGE_SEND_FAILED, async (userId: string) => {
      const state = store.getState()
      const socketUserId = state.auth.user.user.uuid
      const isReceiver: boolean = userId !== socketUserId
      if (!isReceiver) {
        const failedList: LoadingMessage[] = dummyMessageList.map((item: LoadingMessage) => {
          return { ...item, failed: true }
        })
        setDummyMessageList(failedList)
      }
    })

    socket.on('disconnect', async () => {
      socket.connect();
      const state = store.getState()
      const socketUser = state.auth.user.user
      const socketUserId = state.auth.user.user.uuid
      const socketCurrentChatRoomId = state.chatRoom.currentChatRoom?.uuid
      const socketCurrentChatRoomCode = state.chatRoom.currentChatRoom?.code
      const joinChatroomData = { code: socketCurrentChatRoomCode, chatroomId: socketCurrentChatRoomId, userId: socketUserId, user: socketUser }
      socket.emit(JOIN_CHATROOM_SEND, joinChatroomData);
      getAllMessagesByChatRoomId(pageSizeRef.current, true)
      setDummyMessageList([])
    })

    return () => {
      socket.off(MESSAGE_RES)
      socket.off(MESSAGE_SHOW_TYPING_RES)
      socket.off(MESSAGE_HIDE_TYPING_RES)
      socket.off('disconnect')
    }
  }, [messageList, dummyMessageList])

  return (
    <>
      {pageLoading ?
        <div className='page-loader'><Spinner /></div>
        :
        <>
          {!isUser &&
            <GuestHeader />
          }
          <div className='pt-3 pt-xl-5 pb-4 flex-fill vstack container'>
            <div className='hstack justify-content-end gap-3 d-xl-none mb-3'>
              <button className='btn bg-black text-white' onClick={exitChatRoom}>
                Exit Chat Room
              </button>
              <button className='btn btn-primary' onClick={() => dispatch(shareChatRoomModalHandler(true))}>
                <span><FontAwesomeIcon icon={faShareAlt} /></span>
              </button>
            </div>
            <div className="row gy-3 justify-content-center flex-fill">
              <div className="col-xl-4 d-none d-xl-block">
                <ShareChatRoomDetails />
              </div>
              <div className='col-xl-6'>
                <div className="card h-100">
                  <div className="card-header justify-content-between">
                    <div className='fs-5 fw-medium'>
                      <FontAwesomeIcon icon={faComments} />
                    </div>

                    <div className='hstack gap-4'>
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="dictation-mode" onChange={handleDictationMode} checked={isDictationMode} />
                        <label className="form-check-label" htmlFor="dictation-mode">{t('common.DictationMode')}</label>
                      </div>
                    </div>
                  </div>
                  <div ref={messageListRef} className="card-body px-2 px-md-3 vstack gap-3 overflow-y-auto message-list">
                    <div ref={messagesMiddleRef}></div>
                    {messageLoading ?
                      <div className='w-100 h-100 place-center flex-column gap-1'>
                        <Spinner />
                        <small>{t('common.TranslatingMessages')}</small>
                      </div>
                      :
                      <>
                        {
                          !!messageList && messageList.length > 0
                            ?
                            <div className='vstack gap-4'>
                              {messageList.map((message: IMessageX, index: number) => {
                                return (
                                  <MessageBubble
                                    key={`message-${index}}`}
                                    userId={userId}
                                    message={message}
                                    language={language}
                                  />
                                )

                              })}

                              <DummyMessageList dummyMessageList={dummyMessageList} />
                              <div ref={messagesEndRef}></div>
                            </div>
                            :
                            <div className='flex-fill place-center'>No Messages</div>
                        }
                        {typing && <Typing />}
                        <div ref={messagesEndRef}></div>
                      </>
                    }
                  </div>
                  <div className="card-footer">
                    {
                      !isDictationMode ?
                        <MessageBox
                          lines={lines}
                          message={message}
                          isDictationMode={isDictationMode}
                          handleMessage={handleMessage}
                          sendMessage={sendMessage}
                        />
                        :
                        <div className='vstack gap-3 align-items-center'>
                          <VADWithReact sendMessageCallback={sendMessage} />
                        </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <ShareChatRoomModal />
          </div>
        </>
      }
    </>
  );
}

export default ChatRoom