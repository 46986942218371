import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL


export const profileupdate = async (uuid: any, firstName: string, lastName: string, language: any, ) => {
        return axios.post(API_URL + "users/profile-update/" + uuid,{
        firstName: firstName,
        lastName: lastName,
        language: JSON.stringify(language),
    }).then((response) => {
        return response.data;
    });
}

export const profileDelete = async (uuid: any) => {
    return axios
        .put(API_URL + "users/profile-delete/"+ uuid,)
        .then((response) => {
            return response.data;
        });
}

export const updateProfileImage = async (uuid: any, files : any[]) => {
    let formData = new FormData();
    formData.append('photo', files[0]);
    let newHeader = {
        "Content-Type": "multipart/form-data",
    }
    return axios({
        url: API_URL + "users/profileImage-update/" + uuid,
        method: "put",
        headers: newHeader,
        data: formData
    })
}