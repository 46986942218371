import { getJoinedUserByChatroomIdAndUserId } from "src/services/joined-users.service";
import * as actions from "../actions";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface JoinedUsersState {
    isJoinedUser: boolean
    joinedUserAction: string
}

const initialState: JoinedUsersState = {
    isJoinedUser: false,
    joinedUserAction: '',
}


export const getJoinedUserHandler: any = createAsyncThunk(actions.GET_JOINED_USER, (data: any, { rejectWithValue }) =>
    getJoinedUserByChatroomIdAndUserId(data.chatroomId, data.userId).catch((error: any) => error && rejectWithValue(error))
);

//joinedUsersSlice
export const joinedUsersSlice = createSlice({
    name: 'joinedUsersSlice',
    initialState,
    reducers: {
        isJoinedUserHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.isJoinedUser = payload;
        },
        joinedUserActionHandler: (state, { payload }: PayloadAction<string>) => {
            state.joinedUserAction = payload;
        },
    },
    extraReducers: (builder) => {
    }
})

export const { isJoinedUserHandler, joinedUserActionHandler } = joinedUsersSlice.actions;
export default joinedUsersSlice.reducer;