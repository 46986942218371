/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signInHandler, signInMessageHandler, signInRememberMeHandler, signInRememberNameHandler } from 'src/store/slices/authSlice';
import * as ACTION from "src/store/actions/index";
import { toast } from 'react-toastify';
import { RootState } from 'src/store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { IUserLogin } from 'src/common/commonTypes';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import Logo from 'src/assets/images/logo.svg'
import { emailRegex } from 'src/common/constants';



const Signin = () => {
  //single hooks starts here
  let navigate = useNavigate();
  const dispatch = useDispatch()
  const { t } = useTranslation('common');
  //single hooks ends here


  const { signInIsLoading: loading } = useSelector((state: RootState) => state.auth)
  const { signInRememberMe, signInRememberName } = useSelector((state: RootState) => state.auth)
  
  //useStates starts here
  const [passwordType, setPasswordType] = useState("password");
  const [referralUrl, setReferralUrl] = useState("");
  //useStates ends here


  //formik starts here
  const initialValues: IUserLogin = {
    email: signInRememberName,
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('validation.requiredField'))
      .transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          return originalValue.trim();
        }
        return value;
      })
      .matches(emailRegex, (t('validation.notValidEmail')))
      .email(t('validation.notValidEmail')),
    password: Yup.string()
      .required(t('validation.requiredField')).transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          return originalValue.trim();
        }
        return value;
      }),
  });
  //formik ends here


  //functions starts here
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  const handleLogin = async (formValue: IUserLogin) => {
    await dispatch(signInHandler(formValue)).then((res: any) => {
      if (res?.type === `${ACTION.LOGIN}/${ACTION.FULFILLED}`) {
        toast.success((t('toastMessage.loginSuccessFul')), {
          position: 'top-right',
          autoClose: 2500,
        })
        if (
          !referralUrl.includes("chatroom/public") &&
          !referralUrl.includes("chatroom/private")
        ) {
          // Navigate to the dashboard
          navigate("/dashboard");
        } else {
          navigate(referralUrl);
        }
      }
      else {
        if (res?.type === `${ACTION.LOGIN}/${ACTION.REJECTED}`) {
          dispatch(signInMessageHandler(res?.payload?.response?.data?.message))
          toast.error(res?.payload?.response?.data?.message, {
            position: 'top-right',
            autoClose: 2500,
          })
        } else {
          toast.error(String(t('toastMessage.loginFail')), {
            position: 'top-right',
            autoClose: 2500,
          })
        }
      }
    })
  };
  //functions ends here
  let location = useLocation();


  //useEffects starts here
  useEffect(() => {
    let pathname = location.pathname
    setReferralUrl(pathname)
    if (signInRememberMe === false) {
      dispatch(signInRememberNameHandler(''));
    }
    dispatch(signInMessageHandler())
  }, [])
  //useEffects ends here

  return (
    <div className="signup-wrap d-flex flex-column">
      <div className="hstack justify-content-center flex-fill">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 col-xxl-4 col-xl-6 my-1">
              <div className="card border-0 rounded-4">
                <div className="card-body d-flex flex-column flex-fill justify-content-center px-3 px-sm-5 py-4">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleLogin}
                  >
                    <Form className='vstack gap-4'>
                      <img className='logo' src={Logo} alt="logo" />

                      <h1 className="fs-4 fw-bold text-center mb-1">{t('header.btnSignin')}</h1>
                      <div>
                        <Field name="email" type="text" className="form-control"
                          placeholder={t('signIn.placeholderUsername')} />
                        <ErrorMessage name="email" component="div" className="text-danger txt-error" />
                      </div>
                      <div>

                        <div className="password-toggle">
                          <Field name="password" type={passwordType} className="form-control"
                            placeholder={t('common.Password')} />
                          <button type='button' onClick={togglePassword} className="password-toggle-btn btn btn-link p-0 text-black">
                            <FontAwesomeIcon icon={passwordType === 'password' ? faEye : faEyeSlash} />
                          </button>
                        </div>
                        <ErrorMessage name="password" component="div" className="text-danger txt-error" />
                      </div>
                      <div className='hstack justify-content-between'>
                        <div className="form-check">

                          <Field className="form-check-input" name="isRememberMe" type="checkbox" id="user-remember" checked={signInRememberMe} onChange={(e: ChangeEvent<HTMLInputElement>) => dispatch(signInRememberMeHandler(e.target.checked))} />
                          <label className="form-check-label" htmlFor="user-remember">
                            {t('signIn.lblRememberMe')}
                          </label>
                        </div>

                        <Link to="/forgot-password" className="text-decoration-none">{t('signIn.lblForgotPassword')}</Link>
                      </div>

                      <div className='hstack justify-content-center'>

                        <button className="btn btn-primary mt-5" type="submit" disabled={loading} style={{ minWidth: '70%' }}>
                          {loading && <Spinner animation={'border'} size='sm' />}
                          {t('signIn.btnSignIn')}
                        </button>

                      </div>
                      <div className="text-center">
                        <div className='hstack gap-3 mb-3'>
                          <hr className='flex-fill' />
                          <div>or</div>
                          <hr className='flex-fill' />
                        </div>
                        <Link to="/signup" >Sign Up</Link>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;