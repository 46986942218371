import React, { FC } from 'react'
import { Spinner } from 'react-bootstrap'
import DefaultProfileImg from "src/assets/images/DefaultProfileImg.svg"
import { LoadingMessage } from 'src/common/commonTypes'
import AudioMessageDummy from './AudioMessageDummy'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

interface DummyMessageProps {
    message: LoadingMessage
}
const DummyMessage: FC<DummyMessageProps> = ({ message }) => {
    const { user } = useSelector((state: RootState) => state.auth)

    const isSender = true
    const username = user?.user?.firstName + ' ' + user?.user?.lastName
    const userImg = user?.user?.profileImage ?? DefaultProfileImg
    const textMessage = message.text
    const audioMessage = message.audio
    const isFailed = message.failed

    // const [isFailed, setIsFailed] = useState<boolean>(message.failed)

    // useEffect(() => {
    //     const timeoutId = setTimeout(() => {
    //         setIsFailed(true)
    //     }, 1000 * 60);

    //     return () => clearTimeout(timeoutId)
    // })

    return (
        <div className={`hstack gap-2 ${isSender ? 'sender flex-row-reverse' : 'receiver'}`}>
            <div className="bubble-profile-image" data-bs-toggle="title" title={username}>
                <img src={userImg} className="w-100 h-100 object-fit-cover" alt={username} />
            </div>
            <div className='bubble'>
                {audioMessage ?
                    <AudioMessageDummy isSender={isSender} audioBlob={audioMessage} />
                    :
                    textMessage
                }
            </div>
            {
                isFailed ?
                    <FontAwesomeIcon icon={faTimesCircle} size='xl' className='text-danger mt-1' />
                    :
                    <Spinner size='sm' />
            }
        </div>
    )
}

export default DummyMessage