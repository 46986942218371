import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef, MouseEvent } from 'react';

interface CustomToggleBtnProps {
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

const CustomToggleBtn = forwardRef<HTMLButtonElement, CustomToggleBtnProps>(
    ({ onClick }, ref) => (
        <button
            type="button"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            className='btn text-muted bg-transparent size-38'
        >
            <FontAwesomeIcon icon={faEllipsisV} />
        </button>
    )
);

export default CustomToggleBtn;
