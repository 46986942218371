import { useEffect, useState } from 'react';

export function useLocalCameraStream() {
    const [localStream, setLocalStream] = useState<MediaStream | null>(null);

    useEffect(() => {
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
                console.log("1");

                setLocalStream(stream);
            })
            .catch((error) => {
                console.error("Error accessing media devices.", error);
            });;
    }, []);

    return {
        localStream,
    };
}