import axios from "axios";
import { Language } from "src/common/commonTypes";

const API_URL = process.env.REACT_APP_API_URL

export const register = async (firstName: string, lastName: string, email: string, password: string, language: Language) => {
    return axios.post(API_URL + "auth/signup", {
        firstName,
        lastName,
        email,
        password,
        language,
    }).then((response) => {
        return response.data;
    });;
};

export const createGuest = async (username: string, language: Language) => {
    return axios.post(API_URL + "auth/create-guest", {
        username,
        language,
    }).then((response) => {
        return response.data;
    });;
};

export const login = async (email: string, password: string) => {
    return axios
        .post(API_URL + "auth/login", {
            email,
            password,
        })
        .then((response) => {
            return response.data;
        });
};

export const logout = () => {
    sessionStorage.clear()
};

export const forgotPassword = async (email: string) => {
    return axios
        .post(API_URL + "auth/forgot-password", {
            email
        })
        .then((response) => {
            return response.data;
        });
};

export const resetPassword = async (token: string, newPassword: string) => {
    return axios
        .post(API_URL + "auth/reset-password/" + token, {
            newPassword
        })
        .then((response) => {
            return response.data;
        });
};

export const changePassword = async (oldPassword: string, newPassword: string) => {
    return axios
        .post(API_URL + "auth/change-password", {
            oldPassword,
            newPassword
        },
            // { headers: authHeader() }
        )
        .then((response) => {
            return response.data;
        });
};

