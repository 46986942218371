import React, { FC } from 'react'
import ImageComponent from './ImageComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'

interface ImageEditDeleteProps {
    size: number
    imageSrc: string
    editImage: (e: any) => void
    removeImage?: () => void
    isEditMode?: boolean
    hideDeleteButton?: boolean
}
const ImageEditDelete: FC<ImageEditDeleteProps> = ({ size, imageSrc, editImage, removeImage, isEditMode, hideDeleteButton }) => {
    return (
        <div className='position-relative d-inline-block'>
            <ImageComponent size={size} imageSrc={imageSrc} />
            {
                isEditMode &&
                <>
                    <label htmlFor="chatroom-image" className='position-absolute z-1 bottom-0 end-0 mb-0'>
                        <input type="file" accept=".jpg, .jpeg, .png" id='chatroom-image' hidden onChange={(e) => editImage(e)} />
                        <div role='button' title='edit' aria-label='edit' className='btn bg-primary-subtle text-primary rounded-circle p-1 place-center size-42'>
                            <FontAwesomeIcon icon={faPen} />
                        </div>
                    </label>
                    {!hideDeleteButton &&
                        <button onClick={removeImage} type='button' title='delete' aria-label='delete' className='position-absolute z-1 bottom-0 start-0 btn bg-primary-subtle text-primary rounded-circle p-1 place-center size-42'>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    }
                </>
            }

        </div>
    )
}

export default ImageEditDelete