import React, { FC, useEffect, useState } from 'react'
import QRCode, { QRCodeToDataURLOptions } from 'qrcode';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { ChatroomDeletedHandler, exitChatRoomByIdHandler } from 'src/store/slices/chatRoomSlice';
import socket from 'src/common/socket';
import { DELETE_CHATROOM_RES, LEAVE_CHATROOM_SEND, MESSAGE_HIDE_TYPING_SEND } from 'src/common/constants';
import { useNavigate } from 'react-router-dom';
import { RequestData } from 'src/common/commonTypes';
import { logoutHandler } from 'src/store/slices/authSlice';


interface ShareChatRoomDetailsProps {
}

const ShareChatRoomDetails: FC<ShareChatRoomDetailsProps> = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation('common');
    const { user, isUser } = useSelector((state: RootState) => state.auth)
    const { currentChatRoom } = useSelector((state: RootState) => state.chatRoom)
    const userId = user?.user?.uuid
    const currentChatRoomId = currentChatRoom?.uuid ?? ''
    const currentChatRoomCode = currentChatRoom?.code ?? ''
    const data: RequestData = { code: currentChatRoomCode, chatroomId: currentChatRoomId, userId: userId }

    //useStates starts here
    const [qrLink, setQrLink] = useState('');
    const [srcData, setSrc] = useState('');
    //useStates ends here


    const copyLink = async (e: any) => {
        e.preventDefault();
        navigator.clipboard.writeText(qrLink)
        toast.success(t('common.LinkCopied'), {
            position: 'top-right',
            autoClose: 2500,
        })
    }

    const copyPassword = async (e: any) => {
        e.preventDefault();
        navigator.clipboard.writeText(currentChatRoom?.password)
        toast.success(t('common.PasswordCopied'), {
            position: 'top-right',
            autoClose: 2500,
        })
    }

    const qrCodeOption: QRCodeToDataURLOptions = {
        type: 'image/jpeg',
        errorCorrectionLevel: 'H',
        maskPattern: 2,
        margin: 0,
    }

    const hideTyping = () => {
        socket.emit(MESSAGE_HIDE_TYPING_SEND, data)
    }

    const handleLeave = async () => {
        hideTyping()
        socket.emit(LEAVE_CHATROOM_SEND, data);
        navigate("/")
    }
    const exitChatRoom = async () => {
        if (!isUser) {
            dispatch(logoutHandler())
        }
        handleLeave()
        await dispatch(exitChatRoomByIdHandler(data))
    }


    //useEffect starts here
    useEffect(() => {
        const hostname = typeof window !== 'undefined' ? window.location.origin : process.env.RESTURL_SPEAKERS;
        let link;
        if (currentChatRoom?.isPrivate) {
            link = hostname + `/chatroom/private/${currentChatRoom?.code}`;
        } else {
            link = hostname + `/chatroom/public/${currentChatRoom?.code}`;
        }
        setQrLink(link)
        QRCode.toDataURL(link, qrCodeOption).then((setSrc))

    }, [currentChatRoom]);

    useEffect(() => {
        socket.on(DELETE_CHATROOM_RES, async () => {
            exitChatRoom()
            toast.error(t('common.ChatRoomDeleted'), {
                position: 'top-right',
                autoClose: 2500,
            });
            dispatch(ChatroomDeletedHandler(true))
        })

        return () => {
            socket.off(DELETE_CHATROOM_RES)
        }
    }, [])
    //useEffect ends here
    return (
        <div className="card h-100">
            <div className="card-header justify-content-center">
                <div className="fs-4 mb-0 fw-medium text-truncate w-100" title={currentChatRoom?.title?.toUpperCase()}>
                    {currentChatRoom?.title?.toUpperCase()}
                </div>
            </div>
            <div className="card-body vstack align-items-center gap-5">

                {!!srcData &&
                    <div>
                        <img src={srcData} alt='QR code to join meeting' width={'220px'} height={'220px'} />
                    </div>
                }

                <div>
                    <button onClick={copyLink} className="btn bg-primary-subtle text-primary d-inline-flex align-items-center gap-2">
                        {'Copy Link'}<FontAwesomeIcon icon={faCopy} />
                    </button>
                </div>
                {
                    currentChatRoom?.password &&
                    <div className='text-center'>
                        <div className='fw-medium fs-2'>{currentChatRoom?.password}</div>
                        <button onClick={copyPassword} className="btn bg-primary-subtle text-primary d-inline-flex align-items-center gap-2">
                            {'Copy Password'}<FontAwesomeIcon icon={faCopy} />
                        </button>
                    </div>
                }
                <div className='flex-fill'></div>
                <div className='d-none d-xl-block'>
                    <button className='btn bg-black text-white' onClick={exitChatRoom}>
                        Exit Chat Room
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ShareChatRoomDetails
