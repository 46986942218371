import { useParams } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { useAnswerProcessing } from './useAnswerProcessing';
import { useOfferSending } from './useOfferSending';
import { useSendingAnswer } from './useSendingAnswer';
import socket from 'src/common/socket';

export function useChatConnection(peerConnection: RTCPeerConnection) {
    const { roomName } = useParams();

    const { sendOffer } = useOfferSending(peerConnection);

    const { handleConnectionOffer } = useSendingAnswer(peerConnection);

    const { handleOfferAnswer } = useAnswerProcessing(peerConnection);

    const handleConnection = useCallback(() => {
        socket.emit('join_room', roomName);
    }, [roomName]);

    const handleReceiveCandidate = useCallback(
        ({ candidate }: { candidate: RTCIceCandidate }) => {
            peerConnection.addIceCandidate(candidate);
        },
        [peerConnection],
    );

    useEffect(() => {
        socket.connect();
        socket.on('answer', () => {
            console.log('answer');
            return handleOfferAnswer
        });
        socket.on('send_connection_offer', () => {
            console.log('send_connection_offer');
            return handleConnectionOffer
        });
        socket.on('another_person_ready', () => {
            console.log('another_person_ready');
            return sendOffer
        });
        socket.on('connect', () => {
            console.log('connect');
            return handleConnection
        });
        socket.on('send_candidate', () => {
            console.log('send_candidate');
            return handleReceiveCandidate
        });
        return () => {
            socket.off('answer', handleOfferAnswer);
            socket.off('send_connection_offer', handleConnectionOffer);
            socket.off('another_person_ready', sendOffer);
            socket.off('connect', handleConnection);
            socket.off('send_candidate', handleReceiveCandidate);
        };
    }, [
        roomName,
        handleConnection,
        roomName,
        handleConnectionOffer,
        handleOfferAnswer,
        sendOffer,
        handleReceiveCandidate
    ]);
}