import React, { useEffect, useState, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface AudioMessageProps {
  url: string;
  isSender: boolean;
}

const AudioMessage: React.FC<AudioMessageProps> = ({ url, isSender }) => {
  const [playing, setPlaying] = useState<boolean>(false);
  const waveformRef = useRef<WaveSurfer | null>(null);
  const waveRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (waveRef.current) {
      waveformRef.current = WaveSurfer.create({
        barWidth: 3,
        barRadius: 50,
        cursorWidth: 1,
        container: waveRef.current,
        backend: 'WebAudio',
        autoCenter: true,
        height: 26,
        progressColor: '#000',
        waveColor: isSender ? '#fff' : '#aaa',
        cursorColor: 'transparent',
      });
      waveformRef.current.load(url);
      waveformRef.current.on('finish', function () {
        setPlaying(false);
        waveformRef.current && waveformRef.current.seekTo(0);
      });
    }

    return () => {
      waveformRef.current?.destroy();
    };
  }, [url]);

  const handlePlay = () => {
    setPlaying(!playing);
    waveformRef.current?.playPause();
  };

  return (
    <div className={`WaveformContainer`}>
      <button className="PlayButton" onClick={handlePlay}>
        <FontAwesomeIcon icon={!playing ? faPlay : faPause} size='lg' />
      </button>
      <div ref={waveRef} className="Wave" />
      <audio src={url} />
    </div>
  );
};

export default AudioMessage;
