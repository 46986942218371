import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { Provider } from 'react-redux';
import { persistor, store } from 'src/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import "src/styles/main.scss";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";
import { EN } from './common/constants';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
i18next.init({
  resources: {
    en: {
      common: common_en               // 'common' is our custom namespace
    },
    de: {
      common: common_de
    },
  },
  interpolation: { escapeValue: false }, // React already does escaping
  lng: window.localStorage.language,
  fallbackLng: EN,
})


root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
