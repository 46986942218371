import { PayloadAction, createSlice } from '@reduxjs/toolkit'

//modal initial states
export interface IModal {
    createChatRoomModal: boolean
    shareChatRoomModal: boolean
}

const initialState: IModal = {
    createChatRoomModal: false,
    shareChatRoomModal: false
}

//modalSlice
export const modalSlice = createSlice({
    name: 'modalSlice',
    initialState,
    reducers: {
        createChatRoomModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.createChatRoomModal = payload;
        },
        shareChatRoomModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.shareChatRoomModal = payload;
        },
    }
})


export const { createChatRoomModalHandler, shareChatRoomModalHandler } = modalSlice.actions;
export default modalSlice.reducer;