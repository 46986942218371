import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'

interface MessageBoxProps {
    lines: number
    message: string
    isDictationMode: boolean
    sendMessage: () => void
    handleMessage: (e: ChangeEvent<HTMLTextAreaElement>) => void
}
const MessageBox: FC<MessageBoxProps> = ({ lines, message, isDictationMode, handleMessage, sendMessage }) => {
    const { t } = useTranslation('common');

    const maxCharactersLimit = 250

    return (
        <div className='hstack gap-3'>
            <div className='flex-fill message-box'>
                <textarea
                    className={`form-control rounded-${lines > 1 ? '4' : '5'}`}
                    placeholder={t('common.TypeSomething')}
                    value={message}
                    onChange={handleMessage}
                    style={{ height: `${lines * 21}px` }}  //font-size * line-height -> (14 * 1.5 = 21)
                    readOnly={isDictationMode}
                    maxLength={maxCharactersLimit}
                />
                <span className='character-limit position-absolute bottom-0 end-0 mb-2 me-3 text-muted'>  {(maxCharactersLimit - message.length) + '/' + maxCharactersLimit} </span>
            </div>

            <button
                className='btn btn-primary btn-sm rounded-pill size-38 d-flex align-items-center gap-2'
                title='send message'
                aria-label='send message'
                onClick={() => sendMessage()}
            >
                <FontAwesomeIcon icon={faPaperPlane} />
            </button>
        </div>
    )
}

export default MessageBox