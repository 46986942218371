import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import authReducer from './slices/authSlice'
import settingsReducer from './slices/settingsSlice'
import messageReducer from './slices/messagesSlice';
import chatRoomReducer from './slices/chatRoomSlice';
import profileReducer from './slices/profileSlice'
import joinedUsersReducer from './slices/joinedUsersSlice'
import modalReducer from './slices/modalSlice'

//Reducers 
const rootReducer = combineReducers({
    auth: authReducer,
    settings: settingsReducer,
    messages: messageReducer,
    chatRoom: chatRoomReducer,
    profile: profileReducer,
    joinedUser: joinedUsersReducer,
    modal: modalReducer
})

//persist configuration
const persistConfig: any = {
    key: 'root',
    storage: storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

//store configuration
export const store = configureStore({
    reducer: persistedReducer,

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    devTools: process.env.NODE_ENV !== 'production',
})

export const persistor = persistStore(store)
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

