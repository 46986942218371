import React, { FC, useRef, useState } from 'react'
import DefaultChatroomImg from "src/assets/images/default-chatroom.svg"
import { ChatRoom, RequestData } from 'src/common/commonTypes'
import { useNavigate } from 'react-router-dom'
import ImageComponent from 'src/components/ImageComponent'
import { useDispatch, useSelector } from 'react-redux'
import { getChatRoomByCodeHandler, joinChatRoomByIdHandler, leaveChatRoomByIdHandler, UpdateChatRoomByCodeHandler } from 'src/store/slices/chatRoomSlice'
import socket from 'src/common/socket'
import { RootState } from 'src/store/store'
import { toast } from 'react-toastify';
import { deleteChatRoomByIdHandler } from 'src/store/slices/chatRoomSlice';
import * as ACTION from "src/store/actions/index";
import { DELETE_CHATROOM_SEND, JOIN_CHATROOM_SEND, LEAVE_CHATROOM_SEND } from 'src/common/constants'
import { createChatRoomModalHandler } from 'src/store/slices/modalSlice'
import DialogBox from 'src/components/DeleteBox'
import { useTranslation } from 'react-i18next'
import { joinedUserActionHandler } from 'src/store/slices/joinedUsersSlice'
import { Dropdown } from 'react-bootstrap'
import CustomToggleBtn from 'src/components/CustomToggleBtn'
interface ChatRoomCardProps {
    chatroomData: ChatRoom
    myChatRoom?: boolean
}

const ChatRoomCard: FC<ChatRoomCardProps> = ({ chatroomData, myChatRoom }) => {
    const { t } = useTranslation('common');
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const toggleBtnRef = useRef<HTMLButtonElement>(null)
    const { user } = useSelector((state: RootState) => state.auth)
    const [showDelete, setShowDelete] = useState<any>(false);
    const { currentChatRoom } = useSelector((state: RootState) => state.chatRoom)
    const currentChatRoomId = currentChatRoom?.uuid ?? ''
    const currentChatRoomCode = currentChatRoom?.code ?? ''
    const userId = user?.user?.uuid

    const joinChatRoom = async (code: string, chatroomId: string) => {
        const data: RequestData = { code: code, chatroomId: chatroomId, userId: userId }
        if (currentChatRoom) {
            const currentChatRoomData: RequestData = { code: currentChatRoomCode, chatroomId: currentChatRoomId, userId: userId }
            dispatch(leaveChatRoomByIdHandler(currentChatRoomData)).then((response: any) => {
                if (response.type === `${ACTION.JOINEDUSER_LEAVE}/${ACTION.FULFILLED}`) {
                    socket.emit(LEAVE_CHATROOM_SEND, currentChatRoomData);
                }
            })
        }

        dispatch(joinChatRoomByIdHandler(data)).then(async (joinChatRoomResponse: any) => {
            if (joinChatRoomResponse.type === `${ACTION.JOIN_CHATROOM}/${ACTION.FULFILLED}`) {
                dispatch(joinedUserActionHandler(joinChatRoomResponse.payload.action))
                dispatch(getChatRoomByCodeHandler(code)).then((getChatRoomResponse: any) => {
                    if (getChatRoomResponse.type === `${ACTION.CHATROOM_GET_BY_CODE}/${ACTION.FULFILLED}`) {
                        socket.emit(JOIN_CHATROOM_SEND, data);
                        navigate("/chatroom/" + code)
                    } else {
                        toast.error("Not able to join chatroom at the moment. Please try again later.", {
                            position: 'top-right',
                            autoClose: 2500,
                        });
                    }
                })
            } else {
                toast.error("Not able to join chatroom at the moment. Please try again later.", {
                    position: 'top-right',
                    autoClose: 2500,
                });
            }
        })
    }

    const handleDeleteChatRoom = async (code: string, chatroomId: string) => {
        await dispatch(deleteChatRoomByIdHandler(code)).then((response: any) => {
            if (response?.type === `${ACTION.DELETE_CHATROOM_BY_ID}/${ACTION.FULFILLED}`) {
                if (response?.payload?.status === 200) {
                    const data: RequestData = { code: code, chatroomId: chatroomId, userId: userId }
                    socket.emit(DELETE_CHATROOM_SEND, data);
                    toast.success(t('deleteBox.deleteSuccessMessage'), {
                        position: 'top-right',
                        autoClose: 2500,
                    })
                }
                else if (response?.status === 400) {
                    toast.error(t('deleteBox.deleteFailedMessage'), {
                        position: 'top-right',
                        autoClose: 2500,
                    })
                }
            } else {
                if (response?.type === `${ACTION.DELETE_CHATROOM_BY_ID}/${ACTION.REJECTED}`) {
                    if (response?.status === 400) {
                        toast.error('Failed to delete chat room', {
                            position: 'top-right',
                            autoClose: 2500,
                        })
                    }
                }
            }
        }).catch((err: any) => {
            console.log(err)
        })
    }

    const updateChatRoom = (code: string) => {
        dispatch(UpdateChatRoomByCodeHandler(code))
        dispatch(createChatRoomModalHandler(true))
    }

    return (
        <div className="card border-0 shadow-sm rounded-4">
            <div className="card-body vstack gap-3 align-items-center px-4 pt-4 pb-3 position-relative">
                {myChatRoom &&
                    <Dropdown className='position-absolute top-0 end-0 z-2'>
                        <Dropdown.Toggle as={CustomToggleBtn} ref={toggleBtnRef} />
                        <Dropdown.Menu>
                            <Dropdown.Item as="button" onClick={() => updateChatRoom(chatroomData?.code)}>Edit</Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => setShowDelete(true)} className='text-danger'>Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                }
                <div className='position-relative d-inline-block'>
                    <ImageComponent
                        size={100}
                        imageSrc={chatroomData?.chatRoomImage != null ? chatroomData?.chatRoomImage : DefaultChatroomImg}
                    />
                </div>
                <label className='mb-0 d-block text-truncate w-100 text-center' title={chatroomData.title}>
                    {chatroomData.title}
                </label>
            </div>
            <div className="card-footer border-top-0 text-center">
                <button type='button' className='btn btn-primary mw-120' onClick={() => joinChatRoom(chatroomData?.code, chatroomData?.uuid)}>Join</button>
            </div>

            {/* alerts actions starts heres */}
            <DialogBox show={showDelete} clickOk={() => { handleDeleteChatRoom(chatroomData?.code, chatroomData.uuid); setShowDelete(false) }} clickCancel={() => { setShowDelete(false) }} btncancel={t('deleteBox.btnCancel')} btnyes={t('deleteBox.btnDelete')} question={t('deleteBox.deleteMessage')} />
            {/* alerts actions ends heres */}
        </div>

    )
}

export default ChatRoomCard