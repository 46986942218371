import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateChatRoom, getPrivateChatRoomByCodeHandler, joinChatRoomByIdHandler, leaveChatRoomByIdHandler } from 'src/store/slices/chatRoomSlice';
import { RootState } from 'src/store/store';
import { IGuestUser, Language, RequestData } from 'src/common/commonTypes';
import * as ACTION from "src/store/actions/index";
import socket from 'src/common/socket';
import { DELETE_CHATROOM_RES, LEAVE_CHATROOM_SEND } from 'src/common/constants';
import { joinedUserActionHandler } from 'src/store/slices/joinedUsersSlice';
import ReactSelect from 'react-select';
import { defaultLanguage, languages } from 'src/common/languages';
import { createGuestHandler, guestUserHandler } from 'src/store/slices/authSlice';

const JoinPrivateChatroom = () => {
    let navigate = useNavigate();
    let { code } = useParams();
    const dispatch = useDispatch()
    const { t } = useTranslation('common');

    const { currentChatRoom } = useSelector((state: RootState) => state.chatRoom)
    const { user, isUser } = useSelector((state: RootState) => state.auth)
    const currentChatRoomId = currentChatRoom?.uuid ?? ''
    const currentChatRoomCode = currentChatRoom?.code ?? ''
    const isPrivateChatRoom = currentChatRoom?.isPrivate
    const userId = user?.user?.uuid

    const [chatRoomResponse, setChatRoomResponse] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [isGuest, setIsGuest] = useState<boolean>(false);
    const [language, setLanguage] = useState<Language>(defaultLanguage);
    const firstName = user?.user?.firstName
    const lastName = user?.user?.lastName

    interface initialValues {
        username: string
        joinPassword: string
    }

    const initialValues: initialValues = {
        username: isUser ? `${firstName} ${lastName}` : firstName,
        joinPassword: ""
    }

    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .required(t('validation.requiredField')).transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .strict(true)
            .test(
                "no-spaces",
                t('trimMessage.trimTitle'),
                (val: any) =>
                    val &&
                    val.trim() !== ''
            )
            .test(
                "len",
                t('validation.firstnameLimit'),
                (val: any) => {
                    const trimmedValue = val && val.toString().trim();
                    return trimmedValue && trimmedValue.length >= 3 && trimmedValue.length <= 20;
                }
            ),
        joinPassword: Yup.string()
            .when([], {
                is: () => isPrivateChatRoom,
                then: (schema) => schema
                    .required(t('validation.requiredField'))
                    .strict(true),
                otherwise: (schema) => schema.notRequired().nullable()
            })
    });

    const handleLanguage = (selectedLanguge: any) => {
        setLanguage(selectedLanguge)
    };

    const handleJoinChatRoom = async (data: RequestData, username: string) => {
        if (userId &&
            currentChatRoom &&
            currentChatRoomId !== data.chatroomId &&
            (!isUser && firstName !== username)
        ) {
            const currentChatRoomData: RequestData = { code: currentChatRoomCode, chatroomId: currentChatRoomId, userId: userId };
            handleLeaveCurrentChatRoom(currentChatRoomData);
        }

        dispatch(joinChatRoomByIdHandler(data)).then(async (joinChatRoomResponse: any) => {
            if (joinChatRoomResponse.type === `${ACTION.JOIN_CHATROOM}/${ACTION.FULFILLED}`) {
                dispatch(joinedUserActionHandler(joinChatRoomResponse.payload.action));
                dispatch(UpdateChatRoom(chatRoomResponse));
                navigate('/chatroom/' + code);
                setLoading(false);
            } else {
                navigate('/');
                toast.error("Not able to join chatroom at the moment. Please try again later.", {
                    position: 'top-right',
                    autoClose: 2500,
                });
                setLoading(false);
            }
        });
    };

    const handleLeaveCurrentChatRoom = (currentChatRoomData: RequestData) => {
        dispatch(leaveChatRoomByIdHandler(currentChatRoomData)).then((response: any) => {
            if (response.type === `${ACTION.JOINEDUSER_LEAVE}/${ACTION.FULFILLED}`) {
                socket.emit(LEAVE_CHATROOM_SEND, currentChatRoomData);
            }
        });
    };

    const handleJoin = async (formValue: initialValues) => {
        setLoading(true);
        const { username, joinPassword } = formValue;
        const guestUsername = username.trim()
        const chatroomPassword = joinPassword.trim()

        const chatroomId = chatRoomResponse.uuid;

        const data: RequestData = { code: code, chatroomId: chatroomId, userId: userId };

        if (isUser) {
            if (chatRoomResponse.password === chatroomPassword) {
                handleJoinChatRoom(data, username);
            } else {
                toast.error('Incorrect Password', {
                    position: 'top-right',
                    autoClose: 3000,
                });
                setLoading(false);
            }
        } else {
            const handleGuestJoin = async () => {
                if (firstName && firstName === guestUsername) {
                    handleJoinChatRoom(data, username);
                } else {
                    const guestData: IGuestUser = { username: guestUsername, language: language };
                    await dispatch(createGuestHandler(guestData)).then((res: any) => {
                        if (res?.type === `${ACTION.CREATEGUEST}/${ACTION.FULFILLED}`) {
                            const guestId = res?.payload?.user?.uuid;
                            dispatch(guestUserHandler(res?.payload))

                            const guestJoinData: RequestData = { code: code, chatroomId: chatroomId, userId: guestId };
                            handleJoinChatRoom(guestJoinData, username);
                        }
                    });
                }
            };

            if (isPrivateChatRoom) {
                if (chatRoomResponse.password === chatroomPassword) {
                    handleGuestJoin();
                } else {
                    toast.error('Incorrect Password', {
                        position: 'top-right',
                        autoClose: 3000,
                    });
                    setLoading(false);
                }
            } else {
                handleGuestJoin();
            }
        }
    };

    useEffect(() => {
        if (!!code) {

            if (!isUser) {
                setIsGuest(true)
            }

            dispatch(getPrivateChatRoomByCodeHandler(code)).then((result: any) => {
                if (result?.type === `${ACTION.CHATROOM_GET_BY_CODE}/${ACTION.FULFILLED}` && result?.payload) {
                    setChatRoomResponse(result?.payload)
                    if (result?.payload?.createdBy === userId) {
                        dispatch(UpdateChatRoom(result?.payload))
                        navigate('/chatroom/' + code)
                    }
                } else {
                    toast.error("Chatroom not found!", {
                        position: 'top-right',
                        autoClose: 2500,
                    });
                    navigate("/")
                }

            })
        }

        socket.on(DELETE_CHATROOM_RES, async () => {
            toast.error(t('common.ChatRoomDeleted'), {
                position: 'top-right',
                autoClose: 2500,
            });
            if (isUser) {
                navigate("/")
            }
        });

        return () => {
            socket.off(DELETE_CHATROOM_RES)
        }

    }, [code, user])

    return (
        <div className='pt-3 pt-xl-5 pb-4 flex-fill vstack container'>
            <div className="row">
                <div className="col-lg-6">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleJoin}
                        enableReinitialize={false}
                    >
                        <Form autoComplete="off">
                            <h2 className='fw-bold mb-4'>{'Join chatroom - '}<u>{currentChatRoom?.title}</u></h2>

                            <div className="mb-4">
                                <label htmlFor="user-name" className="form-label">{"User name"}</label>
                                <Field type="text" name="username" className="form-control" id="user-name" readOnly={!isGuest} disabled={!isGuest} />
                                <ErrorMessage name="username" component="div" className="text-danger txt-error" />
                            </div>
                            {isPrivateChatRoom && <div className="mb-4">
                                <label htmlFor="chatroom-password" className="form-label">{'Chatroom Password'}</label>
                                <Field type="text" name='joinPassword' className="form-control password-text" id="chatroom-password" placeholder={"Enter Password"} />
                                <ErrorMessage name="joinPassword" component="div" className="text-danger txt-error" />
                            </div>}
                            {!isUser &&
                                <div className="mb-4">
                                    <label className="form-label">Language</label>
                                    <ReactSelect
                                        onChange={handleLanguage}
                                        options={languages}
                                        defaultValue={language}
                                        className="react-select-container mw-100 w-100"
                                        classNamePrefix="react-select"
                                        placeholder="Select language"
                                    />
                                </div>
                            }
                            <div className="text-end">
                                <button type='submit' className="btn btn-primary mw-120" disabled={loading}>{loading ? t('common.LoadingMsg') : "Join"}</button>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default JoinPrivateChatroom
