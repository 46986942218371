import axios from "axios"
import { Language } from "src/common/commonTypes"

const API_URL = process.env.REACT_APP_API_URL

export const getAllMessagesByChatRoomId = async (chatroomId: string, language: Language, pageSize: any, perPage: any, sortColumn: any, sortType: any) => {
    const params = { language, page: pageSize, limit: perPage, sortColumn, sortType };
    return axios
        .get(API_URL + "translate/getAllMessages/" + chatroomId, { params })
        .then((response) => {
            return response.data;
        });
};
