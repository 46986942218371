import React, { useEffect, useState } from 'react'
import ChatRoomCardWrapper from './ChatRoomCardWrapper'
import { ChatRoom } from 'src/common/commonTypes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { getAllChatRoomsHandler } from 'src/store/slices/chatRoomSlice'
import { RootState } from 'src/store/store'
import { useTranslation } from 'react-i18next'
import socket from 'src/common/socket'
import { CREATE_CHATROOM_RES, DELETE_CHATROOM_RES } from 'src/common/constants'
import { createChatRoomModalHandler } from 'src/store/slices/modalSlice'
import CreateChatRoomModals from 'src/components/Modals/CreateChatRoomModals'

const ChatRoomList = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common');
  const { user } = useSelector((state: RootState) => state.auth)
  const userId = user?.user?.uuid

  const [allChatRooms, setAllChatRooms] = useState<ChatRoom[]>([])
  const [myChatRooms, setMyChatRooms] = useState<ChatRoom[]>([])

  const getAllChatRooms = () => {
    dispatch(getAllChatRoomsHandler()).then((response: any) => {
      const data = response.payload;
      const myChatRoomsData = !!data && data.length > 0 ? data.filter((item: ChatRoom) => item.createdBy === userId) : []
      const allChatRoomsData: any = [];
      data?.forEach((item: any) => {
        if (!item.isPrivate && item.createdBy !== userId) {
          allChatRoomsData.push(item);
        }
      });
      setAllChatRooms(allChatRoomsData);
      setMyChatRooms(myChatRoomsData);
    });
  };

  useEffect(() => {
    getAllChatRooms()
  }, [])

  useEffect(() => {
    socket.on(CREATE_CHATROOM_RES, async () => {
      getAllChatRooms()
    })
    socket.on(DELETE_CHATROOM_RES, async (data: string) => {
      getAllChatRooms()
    })

    return () => {
      socket.off(CREATE_CHATROOM_RES)
      socket.off(DELETE_CHATROOM_RES)
    }
  }, [])

  return (
    <>
      <div className='hstack gap-3 justify-content-between'>
        <ul className="nav nav-tabs flex-fill" id="chatrooms-list" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="all-chatrooms" data-bs-toggle="tab" data-bs-target="#all-chatrooms-tab-pane" type="button" role="tab" aria-controls="all-chatrooms-tab-pane" aria-selected="true">
              {t('common.AllChatRooms')}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="my-chatrooms" data-bs-toggle="tab" data-bs-target="#my-chatrooms-tab-pane" type="button" role="tab" aria-controls="my-chatrooms-tab-pane" aria-selected="false">
              {t('common.MyChatRooms')}
            </button>
          </li>
        </ul>
        <button className='btn btn-primary' aria-label='Create a ChatRoom' onClick={() => dispatch(createChatRoomModalHandler(true))}>
          <span className='d-block d-md-none'><FontAwesomeIcon icon={faPlus} /></span>
          <span className='d-none d-md-block'> {t('common.CreateChatRoom')}</span>
        </button>
      </div>
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="all-chatrooms-tab-pane" role="tabpanel" aria-labelledby="all-chatrooms" tabIndex={0}>
          <ChatRoomCardWrapper chatrooms={allChatRooms} myChatRoom={false} />
        </div>
        <div className="tab-pane fade" id="my-chatrooms-tab-pane" role="tabpanel" aria-labelledby="my-chatrooms" tabIndex={0}>
          <ChatRoomCardWrapper chatrooms={myChatRooms} myChatRoom={true} />
        </div>
      </div>

      <CreateChatRoomModals />
    </>
  )
}

export default ChatRoomList