import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { RequestData } from 'src/common/commonTypes'
import { JOIN_CHATROOM_SEND, LEAVE_CHATROOM_SEND } from 'src/common/constants'
import socket from 'src/common/socket'
import { UpdateChatRoom, getPrivateChatRoomByCodeHandler, joinChatRoomByIdHandler, leaveChatRoomByIdHandler } from 'src/store/slices/chatRoomSlice'
import { RootState } from 'src/store/store'
import * as ACTION from "src/store/actions/index";
import { joinedUserActionHandler } from 'src/store/slices/joinedUsersSlice'
import { toast } from 'react-toastify'

const JoinPublicChatroom = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation('common');

    let { code } = useParams();
    const [messageLoading, setMessageLoading] = useState<boolean>(false)
    const { user, isUser } = useSelector((state: RootState) => state.auth)
    const { currentChatRoom } = useSelector((state: RootState) => state.chatRoom)
    const currentChatRoomId = currentChatRoom?.uuid ?? ''
    const currentChatRoomCode = currentChatRoom?.code ?? ''
    const userId = user?.user?.uuid




    useEffect(() => {
        if (isUser) {
            setMessageLoading(true)
            if (!!code) {
                dispatch(getPrivateChatRoomByCodeHandler(code)).then((response: any) => {
                    if (response?.type === `${ACTION.CHATROOM_GET_BY_CODE}/${ACTION.FULFILLED}` && response?.payload) {
                        setMessageLoading(false)
                        const chatroomId = response?.payload?.uuid
                        const data: RequestData = { code: code, chatroomId: chatroomId, userId: userId }
                        if (currentChatRoom) {
                            const currentChatRoomData: RequestData = { code: currentChatRoomCode, chatroomId: currentChatRoomId, userId: userId }
                            dispatch(leaveChatRoomByIdHandler(currentChatRoomData)).then((response: any) => {
                                if (response.type === `${ACTION.JOINEDUSER_LEAVE}/${ACTION.FULFILLED}`) {
                                    socket.emit(LEAVE_CHATROOM_SEND, currentChatRoomData);
                                }
                            })
                        }
                        dispatch(joinChatRoomByIdHandler(data)).then(async (joinChatRoomResponse: any) => {
                            if (joinChatRoomResponse.type === `${ACTION.JOIN_CHATROOM}/${ACTION.FULFILLED}`) {
                                dispatch(joinedUserActionHandler(joinChatRoomResponse.payload.action))
                                socket.emit(JOIN_CHATROOM_SEND, data);
                                dispatch(UpdateChatRoom(response?.payload))
                                navigate('/chatroom/' + code)
                            } else {
                                navigate('/')
                                toast.error("Not able to join chatroom at the moment. Please try again later.", {
                                    position: 'top-right',
                                    autoClose: 2500,
                                });
                            }
                        })
                    } else {
                        navigate('/')
                        toast.error("Chatroom not found!", {
                            position: 'top-right',
                            autoClose: 2500,
                        });
                    }
                })
            } else {
                navigate('/')
            }
        } else {
            navigate(`/chatroom/private/${code}`)
        }
    }, [code])

    return (
        <div className='pt-3 pt-xl-5 pb-4 flex-fill vstack container'>
            {messageLoading &&
                <div className='w-100 h-100 place-center flex-column gap-3'>
                    <Spinner />
                    <div>Just a moment</div>
                </div>
            }
        </div>
    )
}

export default JoinPublicChatroom