import React, { FC } from 'react'

interface ImageComponentProps {
    size: number
    imageSrc: string
}
const ImageComponent: FC<ImageComponentProps> = ({ size, imageSrc }) => {
    return (
        <div className={`rounded-circle border place-center size-${size}`}>
            <img className='w-100 h-100 object-fit-contain rounded-circle' src={imageSrc} />
        </div>
    )
}

export default ImageComponent