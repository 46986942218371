import { faBars, faHome, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { logoutHandler } from 'src/store/slices/authSlice'
import { RootState } from 'src/store/store'
import Logo from 'src/assets/images/logo2.svg'
import { useTranslation } from 'react-i18next'
import LangIcon from "src/assets/images/lang-icon.svg"

const Header = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation('common');
    const { user } = useSelector((state: RootState) => state.auth)

    const handleLogout = () => {
        dispatch(logoutHandler())
        navigate("/");
    }

    //commented for future use
    // const hideTyping = () => {
    //     socket.emit(MESSAGE_HIDE_TYPING_SEND, data)
    // }

    // const handleLeave = async () => {
    //     hideTyping()
    //     socket.emit(LEAVE_CHATROOM_SEND, data);
    //     navigate("/dashboard")
    // }

    // const leaveChatRoom = async () => {
    //     let pathname = location.pathname
    //     if (!pathname.includes('/dashboard')) {
    //         handleLeave()
    //         await dispatch(leaveChatRoomByIdHandler(data))
    //     }
    // }
    // const data: RequestData = { code: currentChatRoomCode, chatroomId: currentChatRoomId, userId: userId }


    return (
        <header className="shadow-sm header navbar navbar-expand-md bg-white sticky-top py-3">
            <nav className="container-fluid justify-content-start bg-white">
                <Link className="navbar-brand headerlogo hstack gap-1" to={"/dashboard"}>
                    <img src={Logo} alt="logo" />
                    <span>{t('common.AppName')}</span>
                </Link>
                <div className='flex-fill text-end'>
                    <button className="navbar-toggler p-0 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span><FontAwesomeIcon icon={faBars} /></span>
                    </button>
                </div>
                <div className="collapse navbar-collapse justify-content-end flex-grow-0" id="navbarNav">
                    <ul className="navbar-nav gap-3 pt-3 pt-md-0">
                        {/* <li className="nav-item">
                            <Link to="/streaming-room/WEBRTC" className='nav-link text-dark hstack gap-1'>
                                <span><FontAwesomeIcon icon={faDashcube} /></span>
                                <span>WEBRTC</span>
                            </Link>
                        </li> */}
                        <li className="nav-item hstack gap-1 text-muted">
                            <span><img src={LangIcon} alt="" height={16} /></span>
                            <span>{user?.user?.language?.label}</span>
                        </li>
                        <li className="nav-item">
                            <Link to="/dashboard" className='nav-link text-dark hstack gap-1'>
                                <span><FontAwesomeIcon icon={faHome} /></span>
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="nav-item dropdown">
                            <button className="nav-link text-dark hstack gap-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {user.user?.img ?
                                    <span className='size-42 place-center rounded-circle border-secondary-subtle'>
                                        <img src={user.user.img} alt="Profile-image" className='img-fluid' />
                                    </span>
                                    :
                                    <span><FontAwesomeIcon icon={faUser} /></span>
                                }
                                <span>{user.user.firstName} {user.user.lastName}</span>
                            </button>

                            <ul className='dropdown-menu dropdown-menu-end'>
                                <li>
                                    <Link className='dropdown-item' to='/profile'>Profile</Link>
                                </li>
                                <li>
                                    <button className='dropdown-item' onClick={handleLogout}>Logout</button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    )
}

export default Header