import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Language } from '../../common/commonTypes'
import { defaultLanguage } from '../../common/languages'

//setting initial states
export interface ISettings {
    language: Language
    isLiveListenMode: boolean
    isDictationMode: boolean
}

const initialState: ISettings = {
    language: defaultLanguage,
    isLiveListenMode: false,
    isDictationMode: true
}

//settingSlice
export const settingsSlice = createSlice({
    name: 'settingsSlice',
    initialState,
    reducers: {
        languageHandler: (state, { payload }: PayloadAction<Language>) => {
            state.language = payload;
        },
        dictationModeHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.isDictationMode = payload;
        },
        liveListenModeHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.isLiveListenMode = payload;
        },
    }
})

export const { languageHandler, dictationModeHandler, liveListenModeHandler } = settingsSlice.actions;
export default settingsSlice.reducer; 