import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSelect, { SingleValueProps } from 'react-select'
import { languages } from 'src/common/languages'
import ImageEditDelete from 'src/components/ImageEditDelete'
import DefaultProfileImg from "src/assets/images/DefaultProfileImg.svg"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IUserProfile, Language } from 'src/common/commonTypes'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/store/store'
import { UpdateProfile, UpdateProfileImage } from 'src/store/slices/profileSlice'
import * as ACTION from "../../store/actions/index";
import { userHandler } from 'src/store/slices/authSlice'
import { toast } from "react-toastify";
import { profileDelete } from 'src/services/profile.service'
import DialogBox from 'src/components/DeleteBox'


const Profile = () => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()

    const { user, isUser } = useSelector((state: RootState) => state.auth)
    const { message } = useSelector((state: RootState) => state.profile)
    const userId = user?.user?.uuid
    const userProfileImage = user?.user?.profileImage
    const defaultLanguage = user?.user?.language
    const defaultLanguageLabel = user?.user?.language?.label
    const deleteProfileRef = useRef<boolean>(false)
    const [profileEdit, setProfileEdit] = useState<boolean>(false);
    const [language, setLanguage] = useState<Language>(defaultLanguage);
    const [imgSrc, setImgSrc] = useState<string>('');
    const [fileSizevalidation, setFileSizeValidation] = useState<boolean>(false);
    const [fileValidationError, setFileValidationError] = useState<boolean>(false);
    const [isDeleteButton, setIsDeleteButton] = useState<boolean>(false);
    const [isShowDeleteBox, setIsShowDeleteBox] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const initialValues: IUserProfile = {
        firstName: '',
        lastName: '',
        email: '',
        language: Object.keys(language).length === 0 ? user?.user?.language : language,
    }

    const [profile, setProfile] = useState(initialValues);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required(t('validation.requiredField'))
            .transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            }).test(
                "no-spaces",
                t('trimMessage.trimTitle'),
                (val: any) =>
                    val.trim() !== ''
            ),
        lastName: Yup.string()
            .required(t('validation.requiredField'))
            .transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            }).test(
                "no-spaces",
                t('trimMessage.trimTitle'),
                (val: any) =>
                    val.trim() !== ''
            ),
    });

    //function start here
    const handleProfileEdit = () => {
        setProfileEdit(!profileEdit)
    };

    const handleLanguage = (value: any) => {
        setLanguage(value);
    }
    const handleprofileUpdate = async (formValue: IUserProfile) => {
        formValue.language = language
        const { firstName, lastName } = formValue;
        const data = {
            uuid: userId,
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            language: Object.keys(language).length === 0 ? user?.user?.language : language,
        }
        setLoading(true);
        await dispatch(UpdateProfile(data)).then((response: any) => {
            if (response.type === `${ACTION.UPDATEPROFILE}/${ACTION.FULFILLED}`) {
                dispatch(userHandler({ ...user, user: response.payload }));
                setProfileEdit(false);
                setLoading(false);
                toast.success(String(t('profile.profileUpdateSuccefully')), {
                    position: 'top-right',
                    autoClose: 2500,
                });
            }
            if (response.type === `${ACTION.UPDATEPROFILE}/${ACTION.REJECTED}`) {
                setLoading(false);
                toast.error(message, {
                    position: 'top-right',
                    autoClose: 2500,
                });
            }
        })
    }


    const editImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
        if (selectedFiles && selectedFiles.length > 0) {
            const file = selectedFiles[0];
            const isValidSize = file.size <= 2 * 1024 * 1024;
            const isValidType = ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type);
            if (isValidSize && isValidType) {
                const data = {
                    uuid: userId,
                    files: Array.from(selectedFiles)
                };
                try {
                    const response = await dispatch(UpdateProfileImage(data));
                    if (response.type === `${ACTION.UPDATEPROFILEIMAGE}/${ACTION.FULFILLED}`) {
                        dispatch(userHandler({ ...user, user: { ...user.user, profileImage: response?.payload?.data?.profileImage } }));
                        setFileSizeValidation(false);
                        setFileValidationError(false);
                        setIsDeleteButton(userProfileImage !== null ? false : true);
                        toast.success(String(t('profile.profileUpdateSuccefully')), {
                            position: 'top-right',
                            autoClose: 2500,
                        });
                    } else if (response.type === `${ACTION.UPDATEPROFILEIMAGE}/${ACTION.REJECTED}`) {
                        toast.error(response.payload.data.message, {
                            position: 'top-right',
                            autoClose: 2500,
                        });
                    }
                } catch (error) {
                    toast.error(String(t('profile.errorInProfileUpdating')), {
                        position: 'top-right',
                        autoClose: 2500,
                    });
                }
            } else {
                setFileSizeValidation(!isValidSize);
                setFileValidationError(!isValidType);
            }
        }
        else {
            setImgSrc(userProfileImage || DefaultProfileImg);
            setIsDeleteButton(true)
        }
    };

    const removeImage = () => {
        setIsShowDeleteBox(true)
    }

    const handleDeleteProfileImage = async () => {
        setIsShowDeleteBox(false)
        if (!imgSrc.includes('/DefaultProfileImg')) {
            setFileSizeValidation(false);
            setFileValidationError(false);
            await profileDelete(userId).then((res: any) => {
                if (res) {
                    deleteProfileRef.current = true
                    setImgSrc(res?.updatedImageData?.profileImage)
                    dispatch(userHandler({ ...user, user: { ...user.user, profileImage: res?.updatedImageData?.profileImage } }));
                    toast.success(String(t('profile.profileImageDeleteSuccefully')), {
                        position: 'top-right',
                        autoClose: 2500,
                    });
                }
            })
            setIsDeleteButton(true)
        }
    }

    const handelCancleButton = () => {
        handleProfileEdit();
        setImgSrc(deleteProfileRef.current || userProfileImage === null ? DefaultProfileImg : userProfileImage);
        setIsDeleteButton(deleteProfileRef.current || userProfileImage === null ? true : false)
        setLanguage(defaultLanguage)
    }

    //function end here

    //useEffects starts here
    useEffect(() => {
        if (isUser) {
            const { firstName, lastName, email, language } = user?.user || {};
            setProfile({ firstName, lastName, email, language });
        }
    }, [isUser, user]);

    useEffect(() => {
        if (deleteProfileRef.current) {
            setImgSrc(DefaultProfileImg);
            setIsDeleteButton(true);
        }
    }, [deleteProfileRef.current])

    useEffect(() => {
        if (fileValidationError || fileSizevalidation) {
            setImgSrc(DefaultProfileImg);
            setIsDeleteButton(true);
        }
        if (userProfileImage != null) {
            setImgSrc(userProfileImage)
            deleteProfileRef.current = false
            setIsDeleteButton(false)
        } else {
            setImgSrc(DefaultProfileImg)
            setIsDeleteButton(true)
        }
    }, [userProfileImage, imgSrc]);

    //useEffects ends here

    return (
        <div className='pt-3 pt-xl-5 pb-4 flex-fill vstack container'>
            <div className="row">
                <div className="col-xl-6">
                    <div className="card rounded-4 border-0">
                        <div className="card-body p-4">
                            <Formik
                                enableReinitialize={true}
                                initialValues={profile}
                                onSubmit={handleprofileUpdate}
                                validationSchema={validationSchema}
                            >
                                <Form className="vstack gap-3">
                                    <div className='vstack gap-4'>
                                        <div className='text-center text-lg-start my-3'>
                                            <ImageEditDelete
                                                size={170}
                                                imageSrc={imgSrc}
                                                editImage={editImage}
                                                removeImage={removeImage}
                                                isEditMode={true}
                                                hideDeleteButton={isDeleteButton}
                                            />
                                            <div>
                                                <span style={{ color: "red" }}>{fileValidationError ? t("validation.FormatNotAllowed") : fileSizevalidation ? t("validation.FileSizeValidation") : ""}</span>
                                            </div>
                                        </div>

                                        <div>
                                            <label className='form-label'>{t('profile.lblFirstName')}</label>
                                            <Field type="text" className="form-control" name="firstName" disabled={profileEdit ? false : true} />
                                            <ErrorMessage name="firstName" component="div" className="text-danger txt-error" />
                                        </div>
                                        <div>
                                            <label className='form-label'>{t('profile.lblLastName')}</label>
                                            <div className="">
                                                <Field type="text" className="form-control" name="lastName" disabled={profileEdit ? false : true} />
                                                <ErrorMessage name="lastName" component="div" className="text-danger txt-error" />
                                            </div>
                                        </div>
                                        <div>
                                            <label className='form-label'>{t('profile.lblEmail')}</label>
                                            <div className="">
                                                <Field type="text" name="email" className="form-control" readOnly disabled />
                                                <ErrorMessage name="email" component="div" className="text-danger txt-error" />
                                            </div>
                                        </div>
                                        <div>
                                            <label className='form-label'>{t('profile.Language')}</label>
                                            {profileEdit ?
                                                <ReactSelect
                                                    onChange={handleLanguage}
                                                    options={languages}
                                                    value={Object.keys(language).length === 0 ? user?.user?.language : language}
                                                    className="react-select-container mw-100 w-100"
                                                    classNamePrefix="react-select"
                                                    placeholder="Select language"
                                                />
                                                :
                                                <Field type="text" name="language" value={defaultLanguageLabel || ''} className="form-control" readOnly disabled />
                                            }
                                        </div>
                                        <div className='d-flex justify-content-between mt-3'>

                                            <div className='w-auto'>
                                                {profileEdit &&
                                                    <button onClick={
                                                        handelCancleButton
                                                    } type='button' className='btn btn-danger'>{"Cancel"}</button>}
                                            </div>
                                            <div className='w-auto'>
                                                {!profileEdit &&
                                                    <button onClick={handleProfileEdit} type='button' className='btn btn-primary'>{t('profile.btnEdit')}</button>}
                                                {profileEdit &&
                                                    <button type='submit' className='btn btn-primary' disabled={loading}>{loading ? t('common.LoadingMsg') : t('profile.btnSave')}</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <DialogBox show={isShowDeleteBox} clickOk={() => { handleDeleteProfileImage() }} clickCancel={() => { setIsShowDeleteBox(false) }} btncancel={t('deleteBox.btnCancel')} btnyes={t('deleteBox.btnDelete')} question={t('deleteBox.ProfileDeleteMessage')} />
        </div>
    )
}

export default Profile