import { faMicrophone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMicVAD, utils } from "@ricky0123/vad-react"
import React, { FC } from "react"
import { AudioFormat } from "src/common/commonTypes"

const APP_VAD_URL = process.env.REACT_APP_VAD_URL
interface VADWithReactProps {
    sendMessageCallback: (blob: AudioFormat) => void
}
async function encodeAndSendWAV(audio: Float32Array, sendMessageCallback: (blob: any) => void) {
    try {
        const wavBuffer = await encodeWAVAsync(audio);
        sendMessageCallback(wavBuffer);
    } catch (error) {
        console.error('Error encoding WAV:', error);
    }
}

function encodeWAVAsync(audio: Float32Array) {
    return new Promise((resolve, reject) => {
        try {
            const wavBuffer = utils.encodeWAV(audio);
            resolve(wavBuffer);
        } catch (error) {
            reject(error);
        }
    });
}

const VADWithReact: FC<VADWithReactProps> = ({ sendMessageCallback }) => {

    const vad = useMicVAD({
        startOnLoad: false,
        workletURL: `${APP_VAD_URL}vad.worklet.bundle.min.js`,
        modelURL: `${APP_VAD_URL}silero_vad.onnx`,
        ortConfig: (ort) => ort.env.wasm.wasmPaths = APP_VAD_URL,
        onSpeechEnd: (audio) => {
            encodeAndSendWAV(audio, sendMessageCallback)
                .catch((error) => {
                    console.error('Error encoding and sending WAV:', error);
                });
        },
    })

    if (vad.loading) {
        return <Loading />
    }

    if (vad.errored) {
        return <Errored />
    }

    const buttonStates = () => {
        if (vad.listening) {
            if (vad.userSpeaking) {
                return 'high-energy';
            } else {
                return 'low-energy';
            }
        } else {
            return '';
        }
    }
    return (
        <>
            <div>
                <button className="mic" onClick={vad.toggle}>
                    <div className={`pulse-ring ${buttonStates()}`}></div>
                    <FontAwesomeIcon icon={faMicrophone} />
                </button>
            </div>
            <div className="fw-semibold">{vad.listening ? "Listening..." : "Tap to Speak"}</div>
        </>
    )
}
export default VADWithReact;



const Loading = () => {
    return (
        <div className="text-center py-4">
            <div className="fs-5">Loading...</div>
        </div>
    )
}

const Errored = () => {
    return (
        <div className="text-center py-4">
            <div className="fs-5 text-danger">Something went wrong</div>
        </div>
    )
}