import React, { useEffect, useState } from 'react'
import { faEye, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalBody } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CreateChatRoom } from 'src/common/commonTypes';
import { RootState } from 'src/store/store';
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from "yup";
import { createChatRoomHandler, getChatRoomByCodeHandler, UpdateChatRoomByCodeHandler, UpdateChatRoomHandler } from 'src/store/slices/chatRoomSlice';
import socket from 'src/common/socket';
import DefaultChatroomImg from "src/assets/images/default-chatroom.svg"
import ImageEditDelete from '../ImageEditDelete';
import { createChatRoomModalHandler } from 'src/store/slices/modalSlice';
import { toast } from 'react-toastify';
import { chatRoomImageDelete } from 'src/services/chat-room.service';
import { CREATE_CHATROOM_SEND, UPDATE_CHATROOM_SEND } from 'src/common/constants';
import * as ACTION from "src/store/actions/index";


const CreateChatRoomModals = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation('common');
    const { user } = useSelector((state: RootState) => state.auth)
    const { updateChatRoomCode } = useSelector((state: RootState) => state.chatRoom)
    const { createChatRoomModal } = useSelector((state: RootState) => state.modal);
    const userId = user?.user?.uuid
    const [files, setFiles] = useState<File[]>([]);
    const [previewImage, setPreviewImage] = useState<string>('');
    const [imgSrc, setImgSrc] = useState<string>('');
    const [fileSizevalidation, setFileSizevalidation] = useState<boolean>(false);
    const [fileValidationError, setFileValidationError] = useState<boolean>(false);
    const [chatRoomName, setChatRoomName] = useState<string>('');
    const [isPrivateChatRoom, setIsPrivateChatRoom] = useState<boolean>(false)
    const [passwordType, setPasswordType] = useState<string>('password');
    const [chatRoomPassword, setChatRoomPassword] = useState<string>('');
    const [isDeleteButton, setIsDeleteButton] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const isUpdate = !!updateChatRoomCode && updateChatRoomCode !== '' ? true : false


    const initialValues: CreateChatRoom = {
        chatRoomTitle: chatRoomName.trim(),
        isPrivateChatRoom: isPrivateChatRoom,
        password: chatRoomPassword
    };

    const validationSchema = Yup.object().shape({
        chatRoomTitle: Yup.string()
            .required(t('validation.requiredField'))
            .transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .strict(true)
            .test(
                "len",
                t('validation.minlengthTitle'),
                (val: any) =>
                    val &&
                    val.toString().length >= 3
            )
            .test(
                "len",
                t('validation.maxlengthTitle'),
                (val: any) =>
                    val &&
                    val.toString().length <= 50
            )
            .test(
                "no-spaces",
                t('trimMessage.trimTitle'),
                (val: any) =>
                    val &&
                    val.trim() !== ''
            ),
        isPrivateChatRoom: Yup.boolean(),
        password: Yup.string().when('isPrivateChatRoom', {
            is: true,
            then: (schema) => schema
                .required(t('validation.requiredField'))
                .trim(t('trimMessage.trimPassword'))
                .matches(/^[A-Za-z0-9#@!%^&*()_+\-=[\]{}|;:'",.<>?/\\`~]+$/, t('validation.specialcharacterMeetingPassword'))
                .strict(true)
                .test(
                    "len",
                    t('validation.minlengthPassword'),
                    (val: any) =>
                        val &&
                        val.toString().length >= 3
                )
                .test(
                    "len",
                    t('validation.passwordLimitMax'),
                    (val: any) =>
                        val &&
                        val.toString().length <= 40
                ),
            otherwise: (schema) => schema.notRequired().nullable()
        })
    });

    const hideChatRoomModal = () => {
        dispatch(UpdateChatRoomByCodeHandler(''))
        dispatch(createChatRoomModalHandler(false))
        setFiles([])
        setPreviewImage('')
        setChatRoomName('')
        setImgSrc(DefaultChatroomImg)
        setChatRoomPassword('')
        setIsPrivateChatRoom(false)
        setFileSizevalidation(false)
        setFileValidationError(false)
    }

    const editImage = (e: any) => {
        const files = e.target.files;
        if (files.length === 0) {
            setFileValidationError(false)
            setFileSizevalidation(false)
        }
        if (files[0]) {
            const url = window.URL.createObjectURL(files[0]);
            setPreviewImage(url)
            let myFiles: any = Array.from(files);
            if (myFiles.length === 0) {
                setFileValidationError(false)
                setFileSizevalidation(false)
            }
            for (let i = 0; i < myFiles.length; i++) {
                const fileType = myFiles[i].type;
                const fileSize = myFiles[i].size; // in bytes4
                // Check file size (2MB limit)
                if (fileSize > 2 * 1024 * 1024) {
                    setFileSizevalidation(true)
                }
                else {
                    setFileSizevalidation(false)
                }
                if (fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'image/jpg') {
                    setFileValidationError(true)
                } else {
                    setFileValidationError(false)
                }
            }
            setFiles(myFiles);
        } else {
            setPreviewImage('')
            setFiles([])
        }
    }

    const removeImage = async () => {
        setPreviewImage('');
        setFiles([])
        setImgSrc(DefaultChatroomImg);
        if (updateChatRoomCode !== "" && !imgSrc.includes('/default-chatroom')) {
            await chatRoomImageDelete(updateChatRoomCode).then((response: any) => {
                if (response) {
                    socket.emit(CREATE_CHATROOM_SEND)
                    toast.success(String(t('common.ChatRoomImageDeleteSuccefully')), {
                        position: 'top-right',
                        autoClose: 2500,
                    });
                }
            }).catch((error) => {
                toast.error(String(t('common.ChatRoomImageDeleteFailed')), {
                    position: 'top-right',
                    autoClose: 2500,
                });
            })
            setIsDeleteButton(true)
        }
    }

    const createChatRoom = (formValue: CreateChatRoom) => {
        const { chatRoomTitle, password } = formValue
        const ChatRoomPassword = isPrivateChatRoom ? password : '';
        const isPrivate = isPrivateChatRoom ? true : false
        const data = {
            createdBy: userId, title: chatRoomTitle, files: files, password: ChatRoomPassword, isPrivate: isPrivate
        }
        setLoading(true);
        if (!fileValidationError && !fileSizevalidation) {
            if (isUpdate) {
                const data = {
                    code: updateChatRoomCode,
                    title: chatRoomTitle,
                    files: files,
                    password: ChatRoomPassword,
                    isPrivate: isPrivate
                }
                dispatch(UpdateChatRoomHandler(data)).then((response: any) => {
                    socket.emit(CREATE_CHATROOM_SEND)
                    socket.emit(UPDATE_CHATROOM_SEND, response?.payload?.data)
                    toast.success(t('common.ChatRoomUpdatedSuccessfully'), {
                        position: 'top-right',
                        autoClose: 2500,
                    })
                    hideChatRoomModal()
                    setLoading(false);
                }).catch((error: any) => {
                    console.log(error, "error")
                    setLoading(false);
                })
            } else {
                dispatch(createChatRoomHandler(data)).then((response: any) => {
                    if (response?.type === `${ACTION.CHATROOM_CREATE}/${ACTION.FULFILLED}`) {
                        socket.emit(CREATE_CHATROOM_SEND)
                        toast.success(t('common.ChatRoomCreatedSuccessfully'), {
                            position: 'top-right',
                            autoClose: 2500,
                        })
                        hideChatRoomModal()
                        setLoading(false);
                    }
                    if (response?.type === `${ACTION.CHATROOM_CREATE}/${ACTION.REJECTED}`) {
                        toast.error(t('common.ChatRoomCreateFailed'), {
                            position: 'top-right',
                            autoClose: 2500,
                        })
                    }
                })
            }
        }
        else {
            setLoading(false);
        }
    }

    const togglePassword = () => {
        if (passwordType === 'password') {
            setPasswordType("text")
            return;
        }
        setPasswordType('password')
    }

    useEffect(() => {
        if (fileValidationError || fileSizevalidation) {
            setImgSrc(DefaultChatroomImg);
            setIsDeleteButton(true);
        } else if (previewImage) {
            setImgSrc(previewImage);
            setIsDeleteButton(false);
        } else {
            setImgSrc(DefaultChatroomImg);
            setIsDeleteButton(true);
        }
    }, [previewImage, fileValidationError, fileSizevalidation]);


    useEffect(() => {
        if (isUpdate) {
            dispatch(getChatRoomByCodeHandler(updateChatRoomCode)).then((res: any) => {
                if (res?.payload?.chatRoomImage === null) {
                    setImgSrc(DefaultChatroomImg)
                    setIsDeleteButton(true)
                } else {
                    setImgSrc(res?.payload?.chatRoomImage)
                    setIsDeleteButton(false)
                }
                setChatRoomName(res?.payload?.title)
                setIsPrivateChatRoom(res?.payload?.isPrivate)
                setChatRoomPassword(res?.payload?.password)
            })
        }
    }, [isUpdate]);

    return (
        <Modal centered size='sm' show={createChatRoomModal} onHide={hideChatRoomModal}>
            <ModalBody>
                <button type='button' onClick={hideChatRoomModal} aria-label='close modal' className='position-absolute z-1 top-0 end-0 btn text-danger size-42 rounded-circle'><FontAwesomeIcon icon={faTimes} size="lg" /></button>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={createChatRoom}
                    enableReinitialize={true}
                >
                    <Form className='vstack gap-4 align-items-center' autoComplete='off' >
                        <ImageEditDelete
                            size={170}
                            imageSrc={imgSrc}
                            editImage={editImage}
                            removeImage={removeImage}
                            isEditMode={true}
                            hideDeleteButton={isDeleteButton}
                        />
                        <span style={{ color: "red" }}>{fileValidationError ? t("validation.FormatNotAllowed") : fileSizevalidation ? t("validation.FileSizeValidation") : ""}</span>
                        <div className='w-100'>
                            <Field name="chatRoomTitle" type="text" className="form-control placeholder-center text-center"
                                placeholder='Enter chat room name'
                                autoComplete="off"
                                onChange={(e: any) => { setChatRoomName(e.target.value) }} value={chatRoomName} />
                            <ErrorMessage name="chatRoomTitle" component="div" className="text-danger txt-error" />
                        </div>
                        <div className='d-flex justify-content-between flex-wrap gap-3'>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" checked={isPrivateChatRoom} onChange={(e: any) => setIsPrivateChatRoom(e.target.checked)} type="checkbox" id="isPrivateChatRoom" name='isPrivateChatRoom' />
                                <label className="form-label mb-0" htmlFor="isPrivateChatRoom">{'Private ChatRoom?'}</label>
                            </div>
                        </div>
                        {isPrivateChatRoom &&
                            <div className='w-100'>
                                <div className='password-toggle'>
                                    <Field autoComplete="off"
                                        type={passwordType} name='password' onChange={(e: any) => setChatRoomPassword(e.target.value.split(" ").join(""))} className=" form-control password " placeholder={"Enter Password"} />
                                    <button type='button' onClick={togglePassword} className="password-toggle-btn btn btn-link p-0 text-black">
                                        <FontAwesomeIcon icon={passwordType === 'password' ? faEye : faEyeSlash} />
                                    </button>
                                    <ErrorMessage name="password" component="div" className="text-danger txt-error" />
                                </div>
                            </div>
                        }
                        <div>
                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                {loading ? t('common.LoadingMsg') : isUpdate ? t('common.UpdateChatRoom') : t('common.CreateChatRoom')}
                            </button>
                        </div>
                    </Form>
                </Formik>
            </ModalBody>
        </Modal>
    )
}

export default CreateChatRoomModals
